<form [formGroup]="optionBuySellForm">
    <div class="row">
        <div class="col-md-3">
            <select class="custom-select" formControlName='expiration_date'
                [ngClass]="{ 'is-invalid': submitted && optionBuySellForm.get('expiration_date').errors }">
                <option>-Expiration Date-</option>
                <option *ngFor="let date of dates" [value]="date">{{date}}</option>
            </select>
            <span *ngIf="submitted && optionBuySellForm['controls'].expiration_date.errors">
                <span class="text-danger"
                    *ngIf="optionBuySellForm['controls'].expiration_date.errors.required">Expirations Date Is
                    Required</span>
            </span>
        </div>
        <div class="col-md-2 marginTop">
            <select class="custom-select" formControlName='strike'
                [ngClass]="{ 'is-invalid': submitted && optionBuySellForm.get('strike').errors }">
                <option selected>-STRIKE-</option>
                <option *ngFor="let strike of strikes" [value]="strike">{{strike}}</option>
            </select>
            <span *ngIf="submitted && optionBuySellForm['controls'].strike.errors">
                <span class="text-danger" *ngIf="optionBuySellForm['controls'].strike.errors.required">Strike Is
                    Required</span>
            </span>
        </div>
        <div class="col-md-2 marginTop">
            <select class="custom-select" formControlName='option_type'
                [ngClass]="{ 'is-invalid': submitted && optionBuySellForm.get('option_type').errors }">
                <option selected>-OPTION TYPE-</option>
                <option *ngFor="let optionType of optionTypeOptions" [value]="optionType.value">
                    {{optionType.name}}</option>
            </select>
            <span *ngIf="submitted && optionBuySellForm['controls'].option_type.errors">
                <span class="text-danger" *ngIf="optionBuySellForm['controls'].option_type.errors.required">Option
                    Type Is
                    Required</span>
            </span>
        </div>
        <div class="col-md-2 marginTop">
            <select class="custom-select" formControlName='side'
                [ngClass]="{ 'is-invalid': submitted && optionBuySellForm.get('side').errors }">
                <option selected>-SIDE-</option>
                <option *ngFor="let side of sideOptions" [value]="side.value">{{side.name}}</option>
            </select>
            <span *ngIf="submitted && optionBuySellForm['controls'].side.errors">
                <span class="text-danger" *ngIf="optionBuySellForm['controls'].side.errors.required">Side Is
                    Required</span>
            </span>
        </div>
        <div class="col-md-3 marginTop">
            <div class="form-group">
                <input [ngClass]="{ 'is-invalid': submitted && optionBuySellForm.get('quantity').errors }" type="number"
                    class="form-control" formControlName="quantity" placeholder="Quantity">
                <span *ngIf="submitted && optionBuySellForm['controls'].quantity.errors">
                    <span class="text-danger" *ngIf="optionBuySellForm['controls'].quantity.errors.required">Quantity
                        Is
                        Required</span>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="form-group">
                <select [ngClass]="{ 'is-invalid': submitted && optionBuySellForm['controls'].type.errors }"
                    class="custom-select" formControlName="type" id="inputGroupSelect01">
                    <option selected>-Type-</option>
                    <option *ngFor="let type of typeOptions" [value]="type.value">{{type.name}}</option>
                </select>
                <span *ngIf="submitted && optionBuySellForm['controls'].type.errors">
                    <span class="text-danger" *ngIf="optionBuySellForm['controls'].type.errors.required">Type Is
                        Required
                    </span>
                </span>
            </div>
        </div>
        <div class="col" *ngIf='optionBuySellForm.get("type").value == typeOption.Stop || optionBuySellForm.get("type").value == typeOption.StopLimit'>
            <div class="form-group">
                <input [ngClass]="{ 'is-invalid': submitted && optionBuySellForm['controls'].stop.errors }"
                    type="number" class="form-control" formControlName="stop" placeholder="Stop">
                <span *ngIf="submitted && optionBuySellForm['controls'].stop.errors">
                    <span class="text-danger" *ngIf="optionBuySellForm['controls'].stop.errors.required">Stop Is
                        Required
                    </span>
                </span>
            </div>
        </div>
        <div class="col" *ngIf='optionBuySellForm.get("type").value == typeOption.Limit || optionBuySellForm.get("type").value == typeOption.StopLimit'>
            <div class="form-group">
                <input [ngClass]="{ 'is-invalid': submitted && optionBuySellForm['controls'].price.errors }"
                    type="number" class="form-control" formControlName="price" placeholder="Price">
                <span *ngIf="submitted && optionBuySellForm['controls'].price.errors">
                    <span class="text-danger" *ngIf="optionBuySellForm['controls'].price.errors.required">Price Is
                        Required
                    </span>
                </span>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <select [ngClass]="{ 'is-invalid': submitted && optionBuySellForm['controls'].duration.errors }"
                    class="custom-select" formControlName="duration" id="inputGroupSelect01">
                    <option selected>-Duration-</option>
                    <option *ngFor="let duration of durationOptions" [value]="duration.value">{{duration.name}}</option>
                </select>
                <span *ngIf="submitted && optionBuySellForm['controls'].duration.errors">
                    <span class="text-danger" *ngIf="optionBuySellForm['controls'].duration.errors.required">Duration
                        Is
                        Required</span>
                </span>
            </div>
        </div>
        
    </div>
    <div class="row">
        <div class="col-md-12">
            <button type="submit" (click)="onSubmit()" class="btn btn-success">Submit</button>
        </div>
    </div>
</form>