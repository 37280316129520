<section class="site-tour p-0 pb-3">
    <h3 class="text-center text-muted">TruCharts Video Guide</h3>
    <div class="row mx-0 mt-3">
        <div class="col-12 col-lg-6 col-xl-3">
            <div class="card shadow-element">
                <div class="card-body">
                    <app-youtube-video-player [VideoId]="'1U9HPiMlLxQ'"></app-youtube-video-player>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-3">
            <div class="card shadow-element">
                <div class="card-body">
                    <app-youtube-video-player [VideoId]="'XitY2aIxf6k'"></app-youtube-video-player>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-3">
            <div class="card shadow-element">
                <div class="card-body">
                    <app-youtube-video-player [VideoId]="'1U9HPiMlLxQ'"></app-youtube-video-player>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-3">
            <div class="card shadow-element">
                <div class="card-body">
                    <app-youtube-video-player [VideoId]="'XitY2aIxf6k'"></app-youtube-video-player>
                </div>
            </div>
        </div>
    </div>
    <div class="d-block mt-3 text-center">
        <a href="#" class="small-link dot-link">View more</a>
    </div>
</section>