<div class="col-md-12">
  <div class="row">
    <div class="col-12">
      <div class="card symbol-title">
        <div class="card-header">
          <h5 class="fw-600 mb-0">{{ symbolName }}</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-4 mb-3">
              <p class="mb-0" [ngSwitch]="symbol?.exch">
                <span *ngSwitchCase="'Q'"
                  >NASDAQ - Real Time Price. Currency in USD</span
                >
                <span *ngSwitchCase="'A'"
                  >AMEX - Real Time Price. Currency in USD</span
                >
                <span *ngSwitchCase="'N'"
                  >NYSE - Real Time Price. Currency in USD</span
                >
                <span *ngSwitchDefault> Real Time Price. Currency in USD</span>
              </p>
              <p class="mb-0">
                O:
                <span
                  class="pr-2"
                  [class.text-danger]="symbol?.open < 0"
                  [class.text-green]="symbol?.open >= 0"
                  >{{
                    !!symbol?.open ? (symbol.open | number : "1.2-2") : "-"
                  }}</span
                >
                H:
                <span
                  class="pr-2"
                  [class.text-danger]="symbol?.high < 0"
                  [class.text-green]="symbol?.high >= 0"
                  >{{
                    !!symbol?.high ? (symbol.high | number : "1.2-2") : "-"
                  }}</span
                >
                L:
                <span
                  class="pr-2"
                  [class.text-danger]="symbol?.low < 0"
                  [class.text-green]="symbol?.low >= 0"
                  >{{
                    !!symbol?.low ? (symbol.low | number : "1.2-2") : "-"
                  }}</span
                >
                C:
                <span
                  class="pr-2"
                  [class.text-danger]="symbol?.close < 0"
                  [class.text-green]="symbol?.close >= 0"
                >
                  {{
                    !!symbol?.close ? (symbol.close | number : "1.2-2") : "-"
                  }}</span
                ><br />
                V:
                <span
                  class="pr-2"
                  [class.text-danger]="symbol?.volume < 0"
                  [class.text-green]="symbol?.volume >= 0"
                  >{{
                    !!symbol?.volume ? (symbol.volume | number : "1.0-0") : "-"
                  }}</span
                >
                Chg:
                <span
                  [class.text-danger]="symbol?.change < 0"
                  [class.text-green]="symbol?.change >= 0"
                  >{{
                    !!symbol?.change ? (symbol.change | number : "1.2-2") : "-"
                  }}</span
                >
              </p>
            </div>
            <div class="col-12 col-md-5">
              <div
                class="d-flex align-items-center justify-content-between flex-wrap"
              >
                <div>
                  <div class="text-center header-center-block">
                    <span class="down-icon" style="font-size: 1.75rem">
                      <i
                        class="fas fa-arrow-down"
                        *ngIf="symbol?.change < 0"
                      ></i>
                      <i
                        class="fas fa-arrow-up text-green"
                        *ngIf="symbol?.change >= 0"
                      ></i>
                    </span>
                    <span
                      class="ml-2 mr-2 mb-0 font-weight-bold px-1"
                      [class.bg-danger-light]="symbol?.change < 0"
                      [class.bg-success-light]="symbol?.change >= 0"
                      >{{ price | number : "1.2-2" }}</span
                    >
                    <span
                      class="mb-0"
                      [class.text-danger]="symbol?.change < 0"
                      [class.text-green]="symbol?.change >= 0"
                      style="font-size: 1.25rem"
                    >
                      {{ change | number : "1.2-2" }}
                      ({{ changePercentage | number : "1.2-2" }} %)</span
                    >
                  </div>
                  <div class="d-flex pt-2">
                    <button
                      type="button"
                      class="btn btn-block btn-success btn mt-2 mr-2"
                      (click)="onBuyClick()"
                    >
                      BUY
                    </button>
                    <button
                      type="button"
                      class="btn btn-block btn-danger btn"
                      (click)="onSellClick()"
                    >
                      SELL
                    </button>
                  </div>
                </div>

                <div class="watch-list-btn-wrapper">
                  <button
                    id="dropdownSubMenu1"
                    href="#"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    (click)="hasPermission()"
                    class="dropdown-toggle no-arrow btn btn-block btn-outline-primary text-primary add-to-watchlist-button mt-5"
                    style="border-radius: 30px; color: #000000"
                  >
                    <i class="far fa-star star text-primary mr-1"></i> Add to
                    watchlist
                  </button>

                  <div class="navbar-collapse order-5" id="navbarCollapse">
                    <ul class="navbar-nav">
                      <li class="nav-item">
                        <ul
                          aria-labelledby="dropdownSubMenu1"
                          class="dropdown-menu border-0 shadow watchlist-box-wapper mt-1"
                          style="max-height: 300px; overflow-y: scroll"
                        >
                          <!-- <div class="triangle-down"></div> -->
                          <ul class="list-unstyled">
                            <li
                              *ngFor="let item of watchLists"
                              class="mb-1 px-3 py-2"
                              (click)="onUpdateWatchlist(item.name)"
                            >
                              <a
                                href="javascript:void(0)"
                                class="d-block text-muted"
                                ><i class="far fa-star mr-3 text-warning"></i
                                >{{ item.name }}</a
                              >
                            </li>
                            <hr class="my-1" />
                            <li class="mt-1 text-center px-3 py-2">
                              <a
                                href="javascript:void(0)"
                                data-toggle="modal"
                                data-target="#watchlist-modal"
                                class="d-block"
                                >Create new Watchlist</a
                              >
                            </li>
                          </ul>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4"></div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-12"></div> -->

      <!-- <div class="col-md-3">
          <div class="navbar-collapse order-3" id="navbarCollapse">
            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <button id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                  class="dropdown-toggle no-arrow btn btn-block btn-outline-primary add-to-watchlist-button mt-2"
                  style="border-radius: 30px; color: #000000;">
                  Chart Settings
                </button>
                <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow watchlist-box-wapper mt-1"
                  style="max-height: 300px; overflow-y: scroll;">
                  <ul class="list-unstyled">
                    <li *ngFor="let item of chartSettingsList" class="mb-1 px-3 py-2"
                      (click)="changeChartSettings(item.name)"><a href="javascript:void(0)"
                        class="d-block text-muted">{{item.name}}</a></li>
                    <hr class="my-1" />
                    <li class="mt-1 text-center px-3 py-2">
                      <a href="javascript:void(0)" data-toggle="modal" data-target="#chartsettings-modal" class="d-block">Create new Chart Settings Template</a>
                    </li>
                  </ul>
                </ul>
              </li>
            </ul>
          </div>
        </div> -->

      <!-- <div class="col-md-7"></div> 
         </div> -->
    </div>
    <!-- <app-trader ></app-trader> -->
    <!-- <div class="col-md-2 d-flex align-items-center justify-content-center py-3">
      <div class="navbar-collapse order-3" id="navbarCollapse">
        <ul class="navbar-nav">
          <li class="nav-item dropdown" #actualTarget>
            <a href="javascript:void(0)" class="nav-link dropdown-toggle no-arrow text-center"
              (click)="showPorfolioOverley($event,op)">
              <span><i class="far fa-star"></i></span>
              <span class="m-0 pl-2">Add to Portfolio</span>
            </a>
            <p-overlayPanel #op styleClass="portfolio-overlayPanel" [dismissable]="true">
              <ng-template pTemplate>
                <div class="card m-0">
                  <div class="card-header">
                    <div class="card-title">
                      <strong>Select your portfolio type</strong>
                    </div>
                    <div class="card-tools">
                      <button type="button" class="btn btn-tool" (click)="hideOverley(op)"><i
                          class="fas fa-times"></i></button>
                    </div>
                  </div>
                  <div class="card-body container">
                    <form role="form">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <p-dropdown placeholder="Test" [options]="portfolios" styleClass="form-control">
                            </p-dropdown>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <label>Type</label>
                            <p-dropdown [options]="types" placeholder="BUY" styleClass="form-control">
                            </p-dropdown>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <label>Price</label>
                            <input id="Price" type="number" pInputText styleClass="form-control">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group m-0">
                            <label>Date</label>
                            <p-calendar [showIcon]="true" styleClass="width-67rem" dateFormat="mm/dd/yy"></p-calendar>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group m-0">
                            <label>Amount</label>
                            <input id="Amount" type="number" pInputText class="form-control">
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <div class="col-9">
                        <a href="javascript:void(0)" class="d-block mt-1"><strong>Create a new holdings
                            portfolio</strong></a>
                      </div>
                      <div class="col-3 text-right">
                        <button class="btn btn-sm bg-warning px-3 text-white">Add</button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-overlayPanel>
          </li>
        </ul>
      </div>
    </div> -->

    <div class="col-md-3 pl-5">
      <div class="row">
        <div class="col">
          <div class="navbar-collapse order-3" id="navbarCollapse">
            <ul class="navbar-nav">
              <li class="nav-item dropdown" *ngIf="!isLoggedIn">
                <!-- <a href="javascript:void(0)" class="nav-link dropdown-toggle no-arrow text-left"
                  (click)="showLoginForm()">
                  <span><i class="far fa-star"></i></span>
                  <span class="m-0 pl-2">Add to Portfolio</span>
                </a> -->
              </li>
              <li class="nav-item dropdown" #actualTarget *ngIf="isLoggedIn">
                <!-- <a href="javascript:void(0)" class="nav-link dropdown-toggle no-arrow text-left"
                  (click)="showPorfolioOverley($event,op)">
                  <span><i class="far fa-star"></i></span>
                  <span class="m-0 pl-2">Add to Portfolio</span>
                </a> -->
                <p-overlayPanel
                  #op
                  styleClass="portfolio-overlayPanel"
                  [dismissable]="true"
                >
                  <ng-template pTemplate>
                    <div class="card m-0">
                      <div class="card-header">
                        <div class="card-title">
                          <strong>Select your portfolio type</strong>
                        </div>
                        <div class="card-tools">
                          <button
                            type="button"
                            class="btn btn-tool"
                            (click)="hideOverley(op)"
                          >
                            <i class="fas fa-times"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body container">
                        <!-- <form role="form"> -->
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <p-dropdown
                                placeholder="Test"
                                [options]="portfolios"
                                styleClass="form-control"
                              >
                              </p-dropdown>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form-group">
                              <label>Type</label>
                              <p-dropdown
                                [options]="types"
                                placeholder="BUY"
                                styleClass="form-control"
                              >
                              </p-dropdown>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>Price</label>
                              <input
                                id="Price"
                                type="number"
                                pInputText
                                styleClass="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form-group m-0">
                              <label>Date</label>
                              <p-calendar
                                [showIcon]="true"
                                styleClass="width-67rem"
                                dateFormat="mm/dd/yy"
                              >
                              </p-calendar>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group m-0">
                              <label>Amount</label>
                              <input
                                id="Amount"
                                type="number"
                                pInputText
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <!-- </form> -->
                      </div>
                      <div class="card-footer">
                        <div class="row">
                          <div class="col-9">
                            <a href="javascript:void(0)" class="d-block mt-1"
                              ><strong
                                >Create a new holdings portfolio</strong
                              ></a
                            >
                          </div>
                          <div class="col-3 text-right">
                            <button
                              class="btn btn-sm bg-warning px-3 text-white"
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-overlayPanel>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col">
          <div class="navbar-collapse order-3" id="navbarCollapse">
            <ul class="navbar-nav">
              <li class="nav-item dropdown" #actualTarget>
                <a href="javascript:void(0)" class="nav-link dropdown-toggle no-arrow text-left">
                  <span><i class="far fa-star"></i></span>
                  <span class="m-0 pl-2">Create. Alert</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>

<div class="modal fade" id="buy-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" [class.bg-secondary]="viewMode === 'DARK_MODE'">
      <form class="form-horizontal buyForm" [formGroup]="buyForm">
        <div class="modal-header">
          <!-- <h4 class="modal-title w-100 text-center">BUY {{symbol.symbol}}</h4> -->
          <p style="font-weight: bold">
            NASDAQ:{{ symbol.symbol }}, Tradier brokerage
          </p>

          <button
            type="button"
            #closeBtn
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div>
          <div class="badge badge-light badge-broker">
            {{ getDisplayValue(quote?.ask - quote?.bid) }}
          </div>
          <div
            class="btn-group col-12 col-sm-12"
            role="group"
            aria-label="Basic example"
          >
            <button
              type="button"
              class="btn btn-secondary btn-lg"
              [class.btn-danger]="sell === true && buy === false"
              (click)="onSellClick()"
            >
              SELL
              <p>{{ getDisplayValue(quote?.bid, "1.2-2") }}</p>
            </button>

            <button
              type="button"
              class="btn btn-primary btn-lg"
              [class.btn-secondary]="sell === true && buy === false"
              [class.btn-buy]="buy"
              (click)="onBuyClick()"
            >
              BUY
              <p>{{ getDisplayValue(quote?.ask) }}</p>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <ul class="nav nav-pills row">
            <li class="nav-item col">
              <a
                class="nav-link text-center"
                (click)="show('MARKET')"
                [class.active]="btnshow === 'MARKET'"
                >MARKET</a
              >
            </li>
            <li class="nav-item col">
              <a
                class="nav-link text-center"
                (click)="show('LIMIT')"
                [class.active]="btnshow === 'LIMIT'"
                >LIMIT</a
              >
            </li>
            <li class="nav-item col">
              <a
                class="nav-link text-center"
                (click)="show('STOP')"
                [class.active]="btnshow === 'STOP'"
                >STOP</a
              >
            </li>
          </ul>

          <hr />
          <div
            *ngIf="
              btnshow === 'MARKET' || btnshow === 'LIMIT' || btnshow === 'STOP'
            "
          >
            <!-- <form> -->
            <div class="row">
              <div
                class="col-md-6"
                *ngIf="btnshow === 'LIMIT' || btnshow === 'STOP'"
              >
                <p class="text-center">Order Price</p>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      value="{{ buyValue }}"
                      (change)="totalOrder($event)"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>
              </div>
              <div
                class="col-md-6"
                *ngIf="
                  btnshow === 'MARKET' ||
                  btnshow === 'LIMIT' ||
                  btnshow === 'STOP'
                "
                [class.col-md-12]="btnshow === 'MARKET'"
              >
                <p class="text-center">Quantity</p>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      value="1"
                      [(ngModel)]="Quantity"
                      (change)="totalOrder($event)"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>
                <p class="text-center text-secondary col-md-12">units</p>
              </div>
            </div>
            <hr />
            <div class="container">
              <div class="row form-group justify-content-md-center">
                <div class="form-group col-md-5 col-sm-5">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      value="profit"
                      [ngModel]="gender"
                      (change)="profitCheck('profit')"
                      [checked]="gridCheck === 'profit'"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <label class="form-check-label" for="gridCheck">
                      Take Profit
                    </label>
                  </div>
                  <div class="">
                    <input
                      type="text"
                      class="form-control col-12"
                      value="{{ profitValue }}"
                      [disabled]="gridCheck === 'loss'"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>

                <div
                  class="form-group col-md-2 col-sm-2 text-secondary price_align"
                >
                  <p class="text-center">Price</p>
                </div>

                <div class="form-group col-md-5 col-sm-5">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      (change)="profitCheck('loss')"
                      value="loss"
                      name="gender"
                      [ngModel]="gender"
                      [checked]="gridCheck === 'loss'"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <label class="form-check-label" for="gridCheck">
                      Take loss
                    </label>
                  </div>
                  <div class="">
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      value="{{ lossValue }}"
                      [disabled]="gridCheck == 'profit'"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="form-group col-md-12 col-sm-12 text-secondary price_align"
            >
              <p class="text-center">
                Time in Force<select>
                  <option value="0">DAY</option>
                  <option value="1">WEEK</option>
                  <option value="2">MONTH</option>
                  <option value="2">GTD</option>
                </select>
              </p>
            </div>

            <div class="col-md-12">
              <button
                type="button"
                class="btn {{ buttonClass }} btn-primary btn-lg btn-block"
                [class.btn-danger]="sell === true && buy === false"
                (click)="gotoTradier()"
              >
                {{ buy ? "BUY" : "SELL" }} {{ quantityValue }} NASDAQ :
                {{ symbol.symbol }}
                {{
                  btnshow === "MARKET"
                    ? "MKT"
                    : btnshow === "LIMIT"
                    ? "LMT"
                    : "STP"
                }}
              </button>
            </div>
            <!-- </form> -->
          </div>
        </div>
        <div class="modal-footer text-center d-block">
          <h3 class="text-left">ORDER INFO</h3>
          <div class="row col-md-12">
            <p class="text-left col-6">Trade Value</p>
            <p class="text-right col-6">{{ totalOrderValue }}</p>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal fade" id="watchlist-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" [class.bg-secondary]="viewMode === 'DARK_MODE'">
      <div class="modal-header">
        Create New Watchlist
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <p class="text-left">Watchlist Name</p>
            <div class="form-row">
              <div class="form-group col-md-12">
                <input
                  type="text"
                  class="form-control"
                  name="chartSettingsName"
                  placeholder=""
                  [(ngModel)]="watchlistName"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <p class="text-left" style="color: red">{{ watchlistError }}</p>
          <button
            type="button"
            (click)="onCreateWatclist()"
            class="btn btn-primary btn-lg btn-block"
          >
            Create Watchlist
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="order-detail">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" [class.bg-secondary]="viewMode === 'DARK_MODE'">
      <div class="modal-header">
        Status
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row text-center">
          <div class="col-sm-12">
            <div class="h3">Equity</div>
          </div>
        </div>
        <div class="row text-center mt-5">
          <div class="col-sm-6">
            <div class="h3">Quantity: {{ quantityValue }}</div>
          </div>
          <div class="col-sm-6">
            <div class="h3">Symbol: {{ symbol.symbol }}</div>
          </div>
        </div>
        <div class="row text-center mt-5">
          <div class="col-sm-6">
            <div class="h3">Type: {{ btnshow }}</div>
          </div>
          <div class="col-sm-6">
            <div class="h3">duration : day</div>
          </div>
        </div>
        <div class="row text-center mt-5">
          <div class="col-sm-12">
            <div class="h3">
              Account Balances:
              {{ usersAccountBalances?.balances?.total_equity }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button
  #showOrderDetail
  style="display: none"
  data-toggle="modal"
  data-target="#order-detail"
>
  btn
</button>
