<div class="row">
    <div class="col-md-12 card-height">
        <div class="card shadow-element">
            <div class="card-body d-inline-flex">

            

    <div class="dropdown-width">
        <label class="poppin-font fw-500 item-title">{{ TC_FAST }}</label>
        <p-dropdown [options]="tcFastResp"
                    [(ngModel)]="signal.tcFast"
                    (onChange)="onChangeDropDown(TC_FAST)"
                    styleClass="w-100"
                    class="w-50">
        </p-dropdown>
    </div>
    <div class="dropdown-width">
        <label class="poppin-font fw-500 item-title">{{ TC_POSITIONAL }}</label>
        <p-dropdown [options]="tcPositionalResp"
                    [(ngModel)]="signal.tcPositional"
                    (onChange)="onChangeDropDown(TC_POSITIONAL)"
                    styleClass="w-100"
                    class="w-50">
        </p-dropdown>
    </div>
    <div class="dropdown-width">
        <label class="poppin-font fw-500 item-title">{{ GOLDEN_SPIKE_STRATEGY }}</label>
        <p-dropdown [options]="goldenSpikeResp"
                    [(ngModel)]="signal.goldenSpike"
                    (onChange)="onChangeDropDown(GOLDEN_SPIKE_STRATEGY)"
                    styleClass="w-100"
                    class="w-50">
        </p-dropdown>
    </div>
    <div class="dropdown-width">
        <label class="poppin-font fw-500 item-title">{{ TURTLE_TRADING_STRATEGY }}</label>
        <p-dropdown [options]="turtalTradingResp"
                    [(ngModel)]="signal.turtalTrading"
                    (onChange)="onChangeDropDown(TURTLE_TRADING_STRATEGY)"
                    styleClass="w-100"
                    class="w-50">
        </p-dropdown>
    </div>
    <div class="dropdown-width">
        <label class="poppin-font fw-500 item-title">{{ TRADER_4_ALL_STRATEGY }}</label>
        <p-dropdown [options]="trade4AllResp"
                    [(ngModel)]="signal.trade4All"
                    (onChange)="onChangeDropDown(TRADER_4_ALL_STRATEGY)"
                    styleClass="w-100"
                    class="w-50">
        </p-dropdown>
    </div>
</div>
</div>
</div>
</div>