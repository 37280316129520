<main class="main-homepage">
	<!-- ==== Hero Area :: Start ==== -->
	<section class="hero--area">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-md-6">
					<!-- hero content -->
					<div class="hero--content">
						
						<h1>
							<div style="margin-bottom: 20px;">
							Your Shortcut to Profitable Trading.</div>
							<h4>	Discover what to buy, when to buy, and when to sell. Try it for yourself and unlock smarter trading now!
					</h4>
				</h1>
						<form>
							<div class="input--group">
								<p-autoComplete
									id="capitalizeInput"
									[(ngModel)]="text"
									name="text"
									[suggestions]="results | async"
									(completeMethod)="search($event)"
									styleClass="w-100"
									inputStyleClass="home-input-search"
									class="w-75"
									placeholder="Search Symbol or Company"
									[autoHighlight]="false"
									emptyMessage="No company found!"
									field="ticker"
									(onSelect)="onSelect($event)"
									(onKeyUp)="onKeyUp($event)"
									[ngModelOptions]="{ standalone: true }"
								>
									<ng-template let-item pTemplate="item">
										<div class="ui-helper-clearfix container">
											<div class="row">
												<div class="col-2">{{ item.ticker }}</div>
												<div class="col-8">{{ item.company }}</div>
												<div class="col-2">
													<i>{{ item.exchange }}</i>
												</div>
											</div>
										</div>
									</ng-template>
								</p-autoComplete>
							</div>
							<button
									(click)="doRegister(freeSubscribe, 'price_1MP8HLCRmDTwTgcmNZxzGjC4')"
									class="button">Try 14 Days Free
									</button>
						</form>
					</div>
				</div>
				<div class="col-md-6">
					<!-- hero img -->
					<!-- <div class="hero--img">
						<img class="img-fluid" src="assets/new-layout/img/hero.png" alt="hero-img" />
						<div class="img--stock">
							<img src="assets/new-layout/img/stock.svg" alt="stock" />
						</div>
					</div> -->
					<lottie-player
						src="assets/new-layout/js/truchartslottie.json"
						background="transparent"
						speed="1"
						style="width: 100%; height: auto; max-width: 100%"
						loop
						autoplay
					></lottie-player>
				</div>
			</div>
		</div>
	</section>
	<!-- ==== Hero Area :: End ==== -->

	<!-- ==== Choose Area :: Start ==== -->
	<section class="choose--area">
		<div class="container">
			<div class="section--tilte" style="margin-bottom:0px !important">
				<h2>Why Choose <span>TruCharts</span></h2>
				<p>
					Boost your stock portfolio with the most comprehensive stock tools on the web
				</p>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="choose--one mt_95">
						<div class="row align-items-center">
							<div class="col-md-6 mt_30">
								<div class="choose--content">
									 <!-- <p><s>When to buy and when to sell a stock?</s></p> -->
									 <h2>TC Fast Strategy Screener
									</h2>
									 <span
										 >Introducing Our TC Fast Strategy Screener — your key to confident and profitable stock trading. Our user-friendly tool enhances stock charts with clear buy and sell indicators, simplifying decision-making for traders of all levels. Make informed moves with visual cues, turning market analysis into action and profit effortlessly.

 
								</span>
										<button
										(click)="doRegister(freeSubscribe, 'price_1MP8HLCRmDTwTgcmNZxzGjC4')"
										class="button">Try 14 Days Free
										</button>
								</div>
							</div>
							<div class="col-md-6 mt_30">
								<div class="choose--img">
									<img
										class="img-fluid"
										src="assets/new-layout/img/strategy.gif"
										alt="choose--img"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="choose--two mt_95">
						<div class="row align-items-center row_reverse">
							<div class="col-md-6 mt_30">
								<div class="choose--content ml_auto">
									 <!-- <p><s>Do you know how to find the best stocks for Investment?</s></p> -->
									 <h2>Real- Time Quotes & Charts with Unlimited Indicators</h2>
									 <span>Respond swiftly to changing market conditions and enhance your ability to execute trades timely for maximum profitability.
										 </span>
									<button
									(click)="doRegister(freeSubscribe, 'price_1MP8HLCRmDTwTgcmNZxzGjC4')"
									class="button">Try 14 Days Free
									</button>
								</div>
							</div>
							<div class="col-md-6 mt_30">
								<div class="choose--img">
									<img
										class="img-fluid"
										src="assets/new-layout/img/Indicators.gif"
										alt="choose--img"
									/>
								</div>
							</div>
						</div>
					</div>
				
					<div class="choose--three mt_95">
						<div class="row align-items-center">
							<div class="col-md-6 mt_30">
								<div class="choose--content">
									<!-- <p><s>How to evaluate your trading strategies?</s></p>  -->
									<h2>Multicharts for Smarter & Faster Trades</h2>
									<span
										>Crystal clear view of price movements, patterns, and indicators all right at your fingertips to make more precise trades and increase your returns.
										</span
									>
									
									<button
									(click)="doRegister(freeSubscribe, 'price_1MP8HLCRmDTwTgcmNZxzGjC4')"
									class="button">Try 14 Days Free
									</button>
								</div>
							</div>
							<div class="col-md-6 mt_30">
								<div class="choose--img">
									<img
										class="img-fluid"
										src="assets/new-layout/img/multicharts.gif"
										alt="choose--img"
									/>
								</div>
							</div>
						</div>
					</div>
					
					<div class="choose--two mt_95">
						<div class="row align-items-center row_reverse">
							<div class="col-md-6 mt_30">
								<div class="choose--content ml_auto">
									 <!-- <p><s>Do you know how to find the best stocks for Investment?</s></p> -->
									 <h2>Backtest & Profit</h2>
									 <span
										 >Backtest will help you evaluate your trading strategies before applying them to
										 the real market.</span
									 >
									<button
									(click)="doRegister(freeSubscribe, 'price_1MP8HLCRmDTwTgcmNZxzGjC4')"
									class="button">Try 14 Days Free
									</button>
								</div>
							</div>
							<div class="col-md-6 mt_30">
								<div class="choose--img">
									<img
										class="img-fluid"
										src="assets/new-layout/img/backtest.gif"
										alt="choose--img"
									/>
								</div>
							</div>
						</div>
					</div>
				
					<div class="choose--five mt_95">
						<div class="row align-items-center">
							<div class="col-md-6 mt_30">
								<div class="choose--content">
									 <!-- <p><s>Do you know how to find the best stocks for Investment?</s></p>  -->
									 <h2>Profit- Ready Stock Lists</h2>
									 <span
										 >Streamline your stock selection process and minimize risk with stock lists that have pre-screened indicators for profit. 
										 </span
									 >
									<button
									(click)="doRegister(freeSubscribe, 'price_1MP8HLCRmDTwTgcmNZxzGjC4')"
									class="button">Try 14 Days Free
									</button>
								</div>
							</div>
							<div class="col-md-6 mt_30">
								<div class="choose--img">
									<img
										class="img-fluid"
										src="assets/new-layout/img/stocklists.gif"
										alt="choose--img"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="choose--four mt_95">
						<div class="row align-items-center row_reverse">
							<div class="col-md-6 mt_30">
								<div class="choose--content ml_auto">
								
									<!-- <p><s>How do I manage my Stock Portfolio?</s></p> -->
									
									<h2>Instant Stock Insights</h2>
									<span
										>Quickly assess key metrics and trends  for potential trades without the need for extensive navigation or clicks. 
										</span
									>

									<button
									(click)="doRegister(freeSubscribe, 'price_1MP8HLCRmDTwTgcmNZxzGjC4')"
									class="button">Try 14 Days Free
									</button>
								</div>
							</div>
							<div class="col-md-6 mt_30">
								<div class="choose--img">
									<img
										class="img-fluid"
										src="assets/new-layout/img/insights.gif"
										alt="choose--img"
									/>
								</div>
							</div>
						</div>
					</div>
				
				</div>
			</div>
		</div>
	</section>
	<!-- ==== Choose Area :: End ==== -->

	<!-- ==== Pricing Area :: Start ==== -->
	<section class="pricing--area">
		<div class="container">
			<div class="pricing--title text-center">
				<div class="heading--and--tab">
					<h2>Pricing</h2>
					<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
						<li class="nav-item">
							<button
								class="nav-link active"
								id="pills-monthly-tab"
								data-bs-toggle="pill"
								data-bs-target="#pills-monthly"
							>
								Monthly
							</button>
						</li>
						<li class="nav-item">
							<button
								class="nav-link"
								id="pills-annual-tab"
								data-bs-toggle="pill"
								data-bs-target="#pills-annual"
							>
								Annual
							</button>
						</li>
					</ul>
				</div>
				<p>
					We offer the best stock tools for your money to skyrocket your portfolio. Try risk-free or choose your plan. 

				</p>
			</div>
			<div class="row">
				<div class="pricing--tab--content">
					<div class="tab-content" id="pills-tabContent">
						<div class="tab-pane fade show active" id="pills-monthly">
							<div class="row justify-content-center">
								<div class="col-lg-10">
									<div class="row justify-content-center">
										<div class="col-xxl-4 col-md-6 mt_30">
											<div class="price--box">
												<div class="plan--area">
													<div class="plan--name">
														<h3>Free Trial</h3>
														<!-- <p>Choose your plan</p> -->
													</div>
													<div class="plan--price">
														<h3>Free</h3>
														<p>For 14 Days</p>
													</div>
												</div>
												<div class="feature--area">
													<h4>Get these powerful features</h4>
													<ul class="feature">
														<li>
															<div class="circle"></div>
															<p>Realtime live quotes</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Realtime Stockcharts (>20+ years charts)</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Fundamental Data w/Charts</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Multiple Chart Technical Indicators</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Live Top Stocks Lists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Detailed Dividend data</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Customizable Stock Screener</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Fastest Backtesting w/technical indicators</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Custom Watchlists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Algo Stock Buy/Sell Signals on charts</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Daily EOD Screened Stock Lists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Ad Free</p>
														</li>
													</ul>
													<a
														(click)="doRegister(freeSubscribe, 'price_1MP8HLCRmDTwTgcmNZxzGjC4')"
														class="button w-100"
														>Get Your Free Trial</a>
												</div>
											</div>
										</div>
										<div class="col-xxl-4 col-md-6 mt_30">
											<div class="price--box active">
												<div class="plan--area">
													<div class="plan--name">
														<h3>Standard</h3>
														<!-- <p>Choose your plan</p> -->
													</div>
													<div class="plan--price">
														<h3>$29</h3>
														<p>Per month</p>
													</div>
												</div>
												<div class="feature--area">
													<h4>Get these powerful features</h4>
													<ul class="feature">
														<li>
															<div class="circle"></div>
															<p>Realtime live quotes</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Realtime Stockcharts (>20+ years charts)</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Fundamental Data w/Charts</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Multiple Chart Technical Indicators</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Live Top Stocks Lists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Detailed Dividend data</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Customizable Stock Screener</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Fastest Backtesting w/technical indicators</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Custom Watchlists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Algo Stock Buy/Sell Signals on charts</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Daily EOD Screened Stock Lists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Ad Free</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Watchlist Stocks Technical Reports (by email)</p>
														</li>
													</ul>
													<a
														(click)="
															makePayment(standard_monthly, 'price_1MP8T8CRmDTwTgcmxHLZx824')
														"
														class="button w-100"
														>Get Started</a
													>
												</div>
											</div>
										</div>
										<div class="col-xxl-4 col-md-6 mt_30">
											<div class="price--box">
												<div class="plan--area">
													<div class="plan--name">
														<h3>Premium</h3>
														<!-- <p>Choose your plan</p> -->
													</div>
													<div class="plan--price">
														<h3>$49</h3>
														<p>Per month</p>
													</div>
												</div>
												<div class="feature--area">
													<h4>Get these powerful features</h4>
													<ul class="feature">
														<li>
															<div class="circle"></div>
															<p>Realtime live quotes</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Realtime Stockcharts (>20+ years charts)</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Fundamental Data w/Charts</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Multiple Chart Technical Indicators</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Live Top Stocks Lists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Detailed Dividend data</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Customizable Stock Screener</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Fastest Backtesting w/technical indicators</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Custom Watchlists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Algo Stock Buy/Sell Signals on charts</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Daily EOD Screened Stock Lists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Ad Free</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Watchlist Stocks Technical Reports (by email)</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Unlimited Equity Trading</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Unlimited Options Trading</p>
														</li>
													</ul>
													<a
														(click)="makePayment(premium_monthly, 'price_1MP8R4CRmDTwTgcmPv7vT61E')"
														class="button w-100"
														>Get Started</a
													>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="tab-pane fade" id="pills-annual">
							<div class="row justify-content-center">
								<div class="col-md-10">
									<div class="row justify-content-center">
										<div class="col-xxl-4 col-md-6 mt_30">
											<div class="price--box">
												<div class="plan--area">
													<div class="plan--name">
														<h3>Free Trial</h3>
														<!-- <p>Choose your plan</p> -->
													</div>
													<div class="plan--price">
														<h3>Free</h3>
														 <p>For 14 Days</p> 
													</div>
												</div>
												<div class="feature--area">
													<h4>Get these powerful features</h4>
													<ul class="feature">
														<li>
															<div class="circle"></div>
															<p>Realtime live quotes</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Realtime Stockcharts (>20+ years charts)</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Fundamental Data w/Charts</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Multiple Chart Technical Indicators</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Live Top Stocks Lists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Detailed Dividend data</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Customizable Stock Screener</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Fastest Backtesting w/technical indicators</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Custom Watchlists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Algo Stock Buy/Sell Signals on charts</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Daily EOD Screened Stock Lists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Ad Free</p>
														</li>
													</ul>
													<a
														(click)="doRegister(freeSubscribe, 'price_1MP8HLCRmDTwTgcmNZxzGjC4')"
														class="button w-100"
														>Get Your Free Trial</a
													>
												</div>
											</div>
										</div>
										<div class="col-xxl-4 col-md-6 mt_30">
											<div class="price--box active">
												<div class="plan--area">
													<div class="plan--name">
														<h3>Standard</h3>
														<p>Choose your plan</p>
													</div>
													<div class="plan--price">
														<h3>$23</h3>
														<p class="mb-1">Per month</p>
														<p>Paid Annually</p>
													</div>
												</div>
												<div class="feature--area">
													<h4>Get these powerful features</h4>
													<ul class="feature">
														<li>
															<div class="circle"></div>
															<p>Realtime live quotes</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Realtime Stockcharts (>20+ years charts)</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Fundamental Data w/Charts</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Multiple Chart Technical Indicators</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Live Top Stocks Lists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Detailed Dividend data</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Customizable Stock Screener</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Fastest Backtesting w/technical indicators</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Custom Watchlists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Algo Stock Buy/Sell Signals on charts</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Daily EOD Screened Stock Lists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Ad Free</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Watchlist Stocks Technical Reports (by email)</p>
														</li>
													</ul>
													<a
														(click)="makePayment(standard_yearly, 'price_1MP8T8CRmDTwTgcmlN4Cy7nQ')"
														class="button w-100"
														>Get Started</a
													>
												</div>
											</div>
										</div>
										<div class="col-xxl-4 col-md-6 mt_30">
											<div class="price--box">
												<div class="plan--area">
													<div class="plan--name">
														<h3>Premium</h3>
														<p>Choose your plan</p>
													</div>
													<div class="plan--price">
														<h3>$39</h3>
														<p class="mb-1">Per month</p>
														<p>Paid Annually</p>
													</div>
												</div>
												<div class="feature--area">
													<h4>Get these powerful features</h4>
													<ul class="feature">
														<li>
															<div class="circle"></div>
															<p>Realtime live quotes</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Realtime Stockcharts (>20+ years charts)</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Fundamental Data w/Charts</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Multiple Chart Technical Indicators</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Live Top Stocks Lists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Detailed Dividend data</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Customizable Stock Screener</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Fastest Backtesting w/technical indicators</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Custom Watchlists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Algo Stock Buy/Sell Signals on charts</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Daily EOD Screened Stock Lists</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Ad Free</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Watchlist Stocks Technical Reports (by email)</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Unlimited Equity Trading</p>
														</li>
														<li>
															<div class="circle"></div>
															<p>Unlimited Options Trading</p>
														</li>
													</ul>
													<a
														(click)="makePayment(premium_yearly, 'price_1MP8R4CRmDTwTgcmFSIz3VgM')"
														class="button w-100"
														>Get Started</a
													>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- ==== Pricing Area :: End ==== -->

	<!-- ==== Testimonial Area :: Start ==== -->
	<section class="testimonial--area">
		<div class="container">
			<div class="section--tilte">
				<h2>What our members are saying about <span>TruCharts Membership</span></h2>
				<p>
					At TruCharts, we're committed to providing the best possible experience for our customers.
					Here's what some of them have to say
				</p>
			</div>
			<!-- testimonial slider -->
			<owl-carousel-o class="testi--slider" [options]="sliderTestiOptions">
				<ng-template carouselSlide>
					<div class="item">
						<!-- slider box -->
						<div class="testi--box">
							<div class="rating--box">
								<img src="assets/new-layout/img/star.svg" alt="star" />
								<img src="assets/new-layout/img/star.svg" alt="star" />
								<img src="assets/new-layout/img/star.svg" alt="star" />
								<img src="assets/new-layout/img/star.svg" alt="star" />
								<img src="assets/new-layout/img/star.svg" alt="star" />
							</div>
							<h3>
								I've used TruCharts for over a year. Its real-time data transformed my trading strategy. The customizable indicators give me a competitive edge. Highly recommended!
							</h3>
							<div class="moderator--area">
								<!-- <img src="assets/new-layout/img/testi.png" /> -->
								<div class="info">
									<h4>Jane T.</h4>
									<p class="mb-0">California</p>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
				<ng-template carouselSlide>
					<div class="item">
						<div class="testi--box">
							<div class="rating--box">
								<img src="assets/new-layout/img/star.svg" alt="star" />
								<img src="assets/new-layout/img/star.svg" alt="star" />
								<img src="assets/new-layout/img/star.svg" alt="star" />
								<img src="assets/new-layout/img/star.svg" alt="star" />
								<img src="assets/new-layout/img/star.svg" alt="star" />
							</div>
							<h3>
								TruCharts advanced features, including backtesting and predictive analytics, have shaped my investment strategies. Invaluable for my investment funds.

							</h3>
							<div class="moderator--area">
								<!-- <img src="assets/new-layout/img/testi.png" /> -->
								<div class="info">
									<h4>David S.</h4>
									<p class="mb-0">Ohio</p>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
				<ng-template carouselSlide>
					<div class="item">
						<div class="testi--box">
							<div class="rating--box">
								<img src="assets/new-layout/img/star.svg" alt="star" />
								<img src="assets/new-layout/img/star.svg" alt="star" />
								<img src="assets/new-layout/img/star.svg" alt="star" />
								<img src="assets/new-layout/img/star.svg" alt="star" />
								<img src="assets/new-layout/img/star.svg" alt="star" />
							</div>
							<h3>
								TruCharts is essential for my day trading. The real-time market data and unlimited indicators ensures I never miss an opportunity. It's a competitive advantage in the fast-paced trading world.

							</h3>
							<div class="moderator--area">
								<!-- <img src="assets/new-layout/img/testi.png" /> -->
								<div class="info">
									<h4>Emily H. </h4>
									<p class="mb-0">Texas</p>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
				<ng-template carouselSlide>
					<div class="item">
						<div class="testi--box">
							<div class="rating--box">
								<img src="assets/new-layout/img/star.svg" alt="star" />
								<img src="assets/new-layout/img/star.svg" alt="star" />
								<img src="assets/new-layout/img/star.svg" alt="star" />
								<img src="assets/new-layout/img/star.svg" alt="star" />
								<img src="assets/new-layout/img/star.svg" alt="star" />
							</div>
							<h3>
								Trucharts features for analyzing trends and historical data have been a game-changer for me. They have boosted my confidence in my trades resulting in noticeable improvements in my portfolio.
								</h3>
							<div class="moderator--area">
								<!-- <img src="assets/new-layout/img/testi.png" /> -->
								<div class="info">
									<h4>Alex B.</h4>
									<p class="mb-0">New York</p>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
			</owl-carousel-o>
		</div>
	</section>
	<!-- ==== Testimonial Area :: End ==== -->

	<!-- ==== Faq Area :: Start ==== -->
	<section class="faq--area">
		<div class="container">
			<div class="section--tilte">
				<h2>Frequently <span>Asked Questions</span></h2>
			</div>
			<div class="row justify-content-center">
				<div class="col-md-8">
					<div class="faq--accordion">
						<!-- accordion -->
						<div class="accordion" id="accordionExample">
							<!-- accordion item -->
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseOne"
										aria-expanded="true"
									>
										<p>How does the free 14 day trial work?</p>
									</button>
								</h2>
								<div
									id="collapseOne"
									class="accordion-collapse collapse show"
									data-bs-parent="#accordionExample"
								>
									<div class="accordion-body">
										<p>
											With our no credit card needed, free trial, you gain access to our ProfitPlus tools for free for 14 days. After your free trial is over, you can choose a plan for a monthly fee to keep access.

										</p>
									</div>
								</div>
							</div>
							<!-- accordion item -->
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										data-bs-toggle="collapse"
										data-bs-target="#collapseTwo"
										aria-expanded="false"
									>
										<p>How much does it cost to subscribe to TruCharts?</p>
									</button>
								</h2>
								<div
									id="collapseTwo"
									class="accordion-collapse collapse"
									data-bs-parent="#accordionExample"
								>
									<div class="accordion-body">
										<p>
											With our trial, the first 14 days of service is totally free. You may upgrade or
											downgrade at any point during the free trial by activating membership after
											paying subscription fees. After 14 days of free trial period, we will charge
											your credit card for another month of whichever service level you last
											selected.
										</p>
									</div>
								</div>
							</div>
							<!-- accordion item -->
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										data-bs-toggle="collapse"
										data-bs-target="#collapseThree"
										aria-expanded="false"
									>
										<p>Can I change or cancel my subscription plan at any time?
										</p>
									</button>
								</h2>
								<div
									id="collapseThree"
									class="accordion-collapse collapse"
									data-bs-parent="#accordionExample"
								>
									<div class="accordion-body">
										<p>
											Yes. With a few simple clicks, you can cancel, upgrade, or downgrade your subscription at any time.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- ==== Faq Area :: End ==== -->

	<!-- ==== Tradier Area :: Start ==== -->
	<section class="tradier--area">
		<div class="container">
			<div class="row">
				<div class="col-xl-6 col-md-7 mt_30">
					<div class="tradier--content">
						<h2>
							Tradier Brokerage Partner offering unlimited flat fee trading with Trucharts Premium
						</h2>
					  <button *ngIf="!authService.isLoggedIn" class="btn btn-default" (click)="
                                doRegister(
                                  freeSubscribe,
                                  'price_1MP8HLCRmDTwTgcmNZxzGjC4'
                                )
                              ">
                    Get Started
                  </button>
					</div>
				</div>
				<div class="col-xl-6 col-md-5 mt_30 position-static">
					<div class="tradier--box">
						<img src="assets/new-layout/img/tradier-logo.svg" alt="tradier-logo" />
						<p>
							TruCharts is a Tradier Brokerage Partner offering unlimited flat fee (stock/options)
							trading from our site (with an approved opened/funded account and includes full site
							access).
						</p>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- ==== Tradier Area :: End ==== -->
</main>
