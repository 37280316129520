export const FundamentalData = {
    exchange: [
        { label: 'Any', value: 'Any'},
        { label: 'AMEX', value: 'AMEX' },
        { label: 'NYSE', value: 'NYSE' },
        { label: 'NASDAQ', value: 'NASDAQ' }
    ],
    price: [
        { label: 'Any', value: 'Any'},
        { label: 'Under $1', value: 'Under $1'},
        { label: 'Under $2', value: 'Under $2'},
        { label: 'Under $3', value: 'Under $3'},
        { label: 'Under $4', value: 'Under $4'},
        { label: 'Under $5', value: 'Under $5'},
        { label: 'Under $7', value: 'Under $7'},
        { label: 'Under $10', value: 'Under $10'},
        { label: 'Under $15', value: 'Under $15'},
        { label: 'Under $20', value: 'Under $20'},
        { label: 'Under $30', value: 'Under $30'},
        { label: 'Under $40', value: 'Under $40'},
        { label: 'Under $50', value: 'Under $50'},
        { label: 'Over $1', value: 'Over $1'},
        { label: 'Over $2', value: 'Over $2'},
        { label: 'Over $3', value: 'Over $3'},
        { label: 'Over $4', value: 'Over $4'},
        { label: 'Over $5', value: 'Over $5'},
        { label: 'Over $7', value: 'Over $7'},
        { label: 'Over $10', value: 'Over $10'},
        { label: 'Over $15', value: 'Over $15'},
        { label: 'Over $20', value: 'Over $20'},
        { label: 'Over $30', value: 'Over $30'},
        { label: 'Over $40', value: 'Over $40'},
        { label: 'Over $50', value: 'Over $50'},
        { label: 'Over $60', value: 'Over $60'},
        { label: 'Over $70', value: 'Over $70'},
        { label: 'Over $80', value: 'Over $80'},
        { label: 'Over $90', value: 'Over $90'},
        { label: 'Over $100', value: 'Over $100'},
        { label: '$1 to $5', value: '$1 to $5'},
        { label: '$1 to $10', value: '$1 to $10'},
        { label: '$1 to $20', value: '$1 to $20'},
        { label: '$5 to $10', value: '$5 to $10'},
        { label: '$5 to $20', value: '$5 to $20'},
        { label: '$5 to $50', value: '$5 to $50'},
        { label: '$10 to $20', value: '$10 to $20'},
        { label: '$10 to $50', value: '$10 to $50'},
        { label: '$20 to $50', value: '$20 to $50'},
        { label: '$50 to $100', value: '$50 to $100'},
        { label: 'Custom', value: 'Custom'}
    ],
    volume: [
        { label: 'Any', value: 'Any'},
        { label: 'Under 50K', value: 'Under 50K'},
        { label: 'Under 100K', value: 'Under 100K'},
        { label: 'Under 500K', value: 'Under 500K'},
        { label: 'Under 750K', value: 'Under 750K'},
        { label: 'Under 1M', value: 'Under 1M'},
        { label: 'Over 50K', value: 'Over 50K'},
        { label: 'Over 100K', value: 'Over 100K'},
        { label: 'Over 500K', value: 'Over 500K'},
        { label: 'Over 750K', value: 'Over 750K'},
        { label: 'Over 1M', value: 'Over 1M'},
        { label: 'Over 2M', value: 'Over 2M'},
        { label: 'Over 5M', value: 'Over 5M'},
        { label: 'Over 10M', value: 'Over 10M'},
        { label: 'Over 20M', value: 'Over 20M'},
        { label: 'Custom', value: 'Custom'}
    ],
    marketCap: [
        { label: 'Any', value: 'Any'},
        { label: 'Massive 200B and above', value: 'Massive 200B and above'},
        { label: 'Large 10B TO 200B', value: 'Large 10B TO 200B' },
        { label: '10B and above', value: '10B and above' },
        { label: 'Mid 2B to 10B', value: 'Mid 2B to 10B' },
        { label: '2B and above', value: '2B and above'},
        { label: 'Small 300M TO 2B', value: 'Small 300M TO 2B' },
        { label: '300M and above', value: '300M and above' },
        { label: 'Micro 300M and below', value: 'Micro 300M and below' },
        { label: '50M and below', value: '50M and below' }
    ],
    dividendYield: [
        { label: 'Any', value: 'Any'},
        { label: 'Massive 200B and above', value: 'Massive 200B and above'},
        { label: 'Large 10B TO 200B', value: 'Large 10B TO 200B' },
        { label: '10B and above', value: '10B and above' },
        { label: 'Mid 2B to 10B', value: 'Mid 2B to 10B' },
        { label: '2B and above', value: '2B and above'},
        { label: 'Small 300M TO 2B', value: 'Small 300M TO 2B' },
        { label: '300M and above', value: '300M and above' },
        { label: 'Micro 300M and below', value: 'Micro 300M and below' },
        { label: '50M and below', value: '50M and below' }
    ],
    peRatio: [
        { label: 'Any', value: 'Any'},
        { label: 'Low (<15)', value: 'Low (<15)'},
        { label: 'Profitable (>0)', value: 'Profitable (>0)' },
        { label: 'High (>50)', value: 'High (>50)' },
        { label: 'Under 5', value: 'Under 5' },
        { label: 'Under 10', value: 'Under 10'},
        { label: 'Under 15', value: 'Under 15' },
        { label: 'Under 20', value: 'Under 20' },
        { label: 'Under 25', value: 'Under 25' },
        { label: 'Under 30', value: 'Under 30' },
        { label: 'Under 35', value: 'Under 35' },
        { label: 'Under 40', value: 'Under 40'},
        { label: 'Under 45', value: 'Under 45' },
        { label: 'Under 50', value: 'Under 55' },
        { label: 'Over 5', value: 'Over 5' },
        { label: 'Over 10', value: 'Over 10' },
        { label: 'Over 15', value: 'Over 15' },
        { label: 'Over 20', value: 'Over 20' },
        { label: 'Over 25', value: 'Over 25' },
        { label: 'Over 30', value: 'Over 30' },
        { label: 'Over 35', value: 'Over 35' },
        { label: 'Over 40', value: 'Over 40' },
        { label: 'Over 45', value: 'Over 45' },
        { label: 'Over 50', value: 'Over 50' }
    ],
    forwardPE: [
        { label: 'Any', value: 'Any'},
        { label: 'Low (<15)', value: 'Low (<15)'},
        { label: 'Profitable (>0)', value: 'Profitable (>0)' },
        { label: 'High (>50)', value: 'High (>50)' },
        { label: 'Under 5', value: 'Under 5' },
        { label: 'Under 10', value: 'Under 10'},
        { label: 'Under 15', value: 'Under 15' },
        { label: 'Under 20', value: 'Under 20' },
        { label: 'Under 25', value: 'Under 25' },
        { label: 'Under 30', value: 'Under 30' },
        { label: 'Under 35', value: 'Under 35' },
        { label: 'Under 40', value: 'Under 40'},
        { label: 'Under 45', value: 'Under 45' },
        { label: 'Under 50', value: 'Under 55' },
        { label: 'Over 5', value: 'Over 5' },
        { label: 'Over 10', value: 'Over 10' },
        { label: 'Over 15', value: 'Over 15' },
        { label: 'Over 20', value: 'Over 20' },
        { label: 'Over 25', value: 'Over 25' },
        { label: 'Over 30', value: 'Over 30' },
        { label: 'Over 35', value: 'Over 35' },
        { label: 'Over 40', value: 'Over 40' },
        { label: 'Over 45', value: 'Over 45' },
        { label: 'Over 50', value: 'Over 50' }
    ],
    pegRatio: [
        { label: 'Any', value: 'Any'},
        { label: 'Low (<1)', value: 'Low (<1)'},
        { label: 'Profitable (>0)', value: 'Profitable (>0)' },
        { label: 'High (>2)', value: 'High (>2)' },
        { label: 'Under 1', value: 'Under 1' },
        { label: 'Under 2', value: 'Under 2'},
        { label: 'Under 3', value: 'Under 3' },
        { label: 'Over 1', value: 'Over 1' },
        { label: 'Over 2', value: 'Over 2'},
        { label: 'Over 3', value: 'Over 3' }
    ],
    priceToSales: [
        { label: 'Any', value: 'Any'},
        { label: 'Low (<1)', value: 'Low (<1)'},
        { label: 'High (>10)', value: 'High (>10)' },

        { label: 'Under 1', value: 'Under 1'},
        { label: 'Under 2', value: 'Under 2' },
        { label: 'Under 3', value: 'Under 3' },
        { label: 'Under 4', value: 'Under 4' },
        { label: 'Under 5', value: 'Under 5' },
        { label: 'Under 6', value: 'Under 6' },
        { label: 'Under 7', value: 'Under 7'},
        { label: 'Under 8', value: 'Under 8' },
        { label: 'Under 9', value: 'Under 9' },
        { label: 'Under 10', value: 'Under 10' },

        { label: 'Over 1', value: 'Over 1' },
        { label: 'Over 2', value: 'Over 2' },
        { label: 'Over 3', value: 'Over 3' },
        { label: 'Over 4', value: 'Over 4' },
        { label: 'Over 5', value: 'Over 5' },
        { label: 'Over 6', value: 'Over 6' },
        { label: 'Over 7', value: 'Over 7' },
        { label: 'Over 8', value: 'Over 8' },
        { label: 'Over 9', value: 'Over 9' },
        { label: 'Over 10', value: 'Over 10' }
    ],
    priceToBook: [
        { label: 'Any', value: 'Any'},
        { label: 'Low (<1)', value: 'Low (<1)'},
        { label: 'High (>5)', value: 'High (>5)' },

        { label: 'Under 1', value: 'Under 1'},
        { label: 'Under 2', value: 'Under 2' },
        { label: 'Under 3', value: 'Under 3' },
        { label: 'Under 4', value: 'Under 4' },
        { label: 'Under 5', value: 'Under 5' },
        { label: 'Under 6', value: 'Under 6' },
        { label: 'Under 7', value: 'Under 7'},
        { label: 'Under 8', value: 'Under 8' },
        { label: 'Under 9', value: 'Under 9' },
        { label: 'Under 10', value: 'Under 10' },

        { label: 'Over 1', value: 'Over 1' },
        { label: 'Over 2', value: 'Over 2' },
        { label: 'Over 3', value: 'Over 3' },
        { label: 'Over 4', value: 'Over 4' },
        { label: 'Over 5', value: 'Over 5' },
        { label: 'Over 6', value: 'Over 6' },
        { label: 'Over 7', value: 'Over 7' },
        { label: 'Over 8', value: 'Over 8' },
        { label: 'Over 9', value: 'Over 9' },
        { label: 'Over 10', value: 'Over 10' }
    ],
    priceToCash: [
        { label: 'Any', value: 'Any'},
        { label: 'Low (<3)', value: 'Low (<3)'},
        { label: 'High (>50)', value: 'High (>50)' },

        { label: 'Under 1', value: 'Under 1'},
        { label: 'Under 2', value: 'Under 2' },
        { label: 'Under 3', value: 'Under 3' },
        { label: 'Under 4', value: 'Under 4' },
        { label: 'Under 5', value: 'Under 5' },
        { label: 'Under 6', value: 'Under 6' },
        { label: 'Under 7', value: 'Under 7'},
        { label: 'Under 8', value: 'Under 8' },
        { label: 'Under 9', value: 'Under 9' },
        { label: 'Under 10', value: 'Under 10' },

        { label: 'Over 1', value: 'Over 1' },
        { label: 'Over 2', value: 'Over 2' },
        { label: 'Over 3', value: 'Over 3' },
        { label: 'Over 4', value: 'Over 4' },
        { label: 'Over 5', value: 'Over 5' },
        { label: 'Over 6', value: 'Over 6' },
        { label: 'Over 7', value: 'Over 7' },
        { label: 'Over 8', value: 'Over 8' },
        { label: 'Over 9', value: 'Over 9' },
        { label: 'Over 10', value: 'Over 10' },
        { label: 'Over 20', value: 'Over 20' },
        { label: 'Over 30', value: 'Over 30' },
        { label: 'Over 40', value: 'Over 40' },
        { label: 'Over 50', value: 'Over 50' }
    ],
    priceToCashflow: [
        { label: 'Any', value: 'Any'},
        { label: 'Low (<15)', value: 'Low (<15)'},
        { label: 'High (>50)', value: 'High (>50)' },
        { label: 'Under 5', value: 'Under 5' },
        { label: 'Under 10', value: 'Under 10'},
        { label: 'Under 15', value: 'Under 15' },
        { label: 'Under 20', value: 'Under 20' },
        { label: 'Under 25', value: 'Under 25' },
        { label: 'Under 30', value: 'Under 30' },
        { label: 'Under 35', value: 'Under 35' },
        { label: 'Under 40', value: 'Under 40'},
        { label: 'Under 45', value: 'Under 45' },
        { label: 'Under 50', value: 'Under 50' },
        { label: 'Under 60', value: 'Under 60' },
        { label: 'Under 70', value: 'Under 70' },
        { label: 'Under 80', value: 'Under 80' },
        { label: 'Under 90', value: 'Under 90' },
        { label: 'Under 100', value: 'Under 100' },
        { label: 'Over 5', value: 'Over 5' },
        { label: 'Over 10', value: 'Over 10' },
        { label: 'Over 15', value: 'Over 15' },
        { label: 'Over 20', value: 'Over 20' },
        { label: 'Over 25', value: 'Over 25' },
        { label: 'Over 30', value: 'Over 30' },
        { label: 'Over 35', value: 'Over 35' },
        { label: 'Over 40', value: 'Over 40' },
        { label: 'Over 45', value: 'Over 45' },
        { label: 'Over 50', value: 'Over 50' },
        { label: 'Over 60', value: 'Over 60' },
        { label: 'Over 70', value: 'Over 70' },
        { label: 'Over 80', value: 'Over 80' },
        { label: 'Over 90', value: 'Over 90' },
        { label: 'Over 100', value: 'Over 100' }
    ],
    earningDate: [
        { label: 'Any', value: 'Any'},
        { label: 'Today', value: 'Today'},
        { label: 'Today Before Market Open', value: 'Today Before Market Open' },
        { label: 'Today After Market Close', value: 'Today After Market Close' },
        { label: 'Tomorrow', value: 'Tomorrow' },
        { label: 'Tomorrow After Market Close', value: 'Tomorrow After Market Close'},
        { label: 'Yesterday', value: 'Yesterday' },
        { label: 'Yesterday Before Market Open', value: 'Yesterday Before Market Open' },
        { label: 'Yesterday After Market Close', value: 'Yesterday After Market Close' },
        { label: 'Next 5 Days', value: 'Next 5 Days' },
        { label: 'This Week', value: 'This Week' },
        { label: 'Next Week', value: 'Next Week' },
        { label: 'Previous Week', value: 'Previous Week' },
        { label: 'This Month', value: 'This Month' }
    ],
    epsGrowth: [
        { label: 'Any', value: 'Any'},
        { label: 'Negative (<0%)', value: 'Negative (<0%)'},
        { label: 'Positive (>0%)', value: 'Positive (>0%)' },
        { label: 'Positive Low (0-10%)', value: 'Positive Low (0-10%)' },
        { label: 'High (>25%)', value: 'High (>25%)'},
        { label: 'Under 5%', value: 'Under 5%'},
        { label: 'Under 10%', value: 'Under 10%'},
        { label: 'Under 15%', value: 'Under 15%' },
        { label: 'Under 20%', value: 'Under 20%' },
        { label: 'Under 25%', value: 'Under 25%' },
        { label: 'Under 30%', value: 'Under 30%' },

        { label: 'Over 5%', value: 'Over 5%' },
        { label: 'Over 10%', value: 'Over 10%' },
        { label: 'Over 15%', value: 'Over 15%' },
        { label: 'Over 20%', value: 'Over 20%' },
        { label: 'Over 25%', value: 'Over 25%' },
        { label: 'Over 30%', value: 'Over 30%' }
    ],
   
    returnOnAssets: [
        { label: 'Any', value: 'Any'},
       
        { label: 'Positive (>0%)', value: 'Positive (>0%)' },
        { label: 'Negative (<0%)', value: 'Negative (<0%)'},
        { label: 'Very Positive (>15%)', value: 'Very Positive (>15%)' },
        { label: 'Very Negative (<-15%)', value: 'Very Negative (<-15%)' },
    
        { label: 'Under -5%', value: 'Under -5%'},
        { label: 'Under -10%', value: 'Under -10%'},
        { label: 'Under -15%', value: 'Under -15%' },
        { label: 'Under -20%', value: 'Under -20%' },
        { label: 'Under -25%', value: 'Under -25%' },
        { label: 'Under -30%', value: 'Under -30%' },
        { label: 'Under -35%', value: 'Under -35%' },
        { label: 'Under -40%', value: 'Under -40%' },
        { label: 'Under -45%', value: 'Under -45%' },
        { label: 'Under -50%', value: 'Under -50%' },
        { label: 'Over +5%', value: 'Over +5%' },
        { label: 'Over +10%', value: 'Over +10%' },
        { label: 'Over +15%', value: 'Over +15%' },
        { label: 'Over +20%', value: 'Over +20%' },
        { label: 'Over +25%', value: 'Over +25%' },
        { label: 'Over +30%', value: 'Over +30%' },
        { label: 'Over +35%', value: 'Over +35%' },
        { label: 'Over +40%', value: 'Over +40%' },
        { label: 'Over +45%', value: 'Over +45%' },
        { label: 'Over +50%', value: 'Over +50%' }
    ],
    returnOnEquity: [
        { label: 'Any', value: 'Any'},
       
        { label: 'Positive (>0%)', value: 'Positive (>0%)' },
        { label: 'Negative (<0%)', value: 'Negative (<0%)'},
        { label: 'Very Positive (>15%)', value: 'Very Positive (>15%)' },
        { label: 'Very Negative (<-15%)', value: 'Very Negative (<-15%)' },
    
        { label: 'Under -5%', value: 'Under -5%'},
        { label: 'Under -10%', value: 'Under -10%'},
        { label: 'Under -15%', value: 'Under -15%' },
        { label: 'Under -20%', value: 'Under -20%' },
        { label: 'Under -25%', value: 'Under -25%' },
        { label: 'Under -30%', value: 'Under -30%' },
        { label: 'Under -35%', value: 'Under -35%' },
        { label: 'Under -40%', value: 'Under -40%' },
        { label: 'Under -45%', value: 'Under -45%' },
        { label: 'Under -50%', value: 'Under -50%' },
        { label: 'Over +5%', value: 'Over +5%' },
        { label: 'Over +10%', value: 'Over +10%' },
        { label: 'Over +15%', value: 'Over +15%' },
        { label: 'Over +20%', value: 'Over +20%' },
        { label: 'Over +25%', value: 'Over +25%' },
        { label: 'Over +30%', value: 'Over +30%' },
        { label: 'Over +35%', value: 'Over +35%' },
        { label: 'Over +40%', value: 'Over +40%' },
        { label: 'Over +45%', value: 'Over +45%' },
        { label: 'Over +50%', value: 'Over +50%' }
    ],
    returnOnInvestment: [
        { label: 'Any', value: 'Any'},
       
        { label: 'Positive (>0%)', value: 'Positive (>0%)' },
        { label: 'Negative (<0%)', value: 'Negative (<0%)'},
        { label: 'Very Positive (>15%)', value: 'Very Positive (>15%)' },
        { label: 'Very Negative (<-15%)', value: 'Very Negative (<-15%)' },
        { label: 'Under -5%', value: 'Under -5%'},
        { label: 'Under -10%', value: 'Under -10%'},
        { label: 'Under -15%', value: 'Under -15%' },
        { label: 'Under -20%', value: 'Under -20%' },
        { label: 'Under -25%', value: 'Under -25%' },
        { label: 'Under -30%', value: 'Under -30%' },
        { label: 'Under -35%', value: 'Under -35%' },
        { label: 'Under -40%', value: 'Under -40%' },
        { label: 'Under -45%', value: 'Under -45%' },
        { label: 'Under -50%', value: 'Under -50%' },
        { label: 'Over +5%', value: 'Over +5%' },
        { label: 'Over +10%', value: 'Over +10%' },
        { label: 'Over +15%', value: 'Over +15%' },
        { label: 'Over +20%', value: 'Over +20%' },
        { label: 'Over +25%', value: 'Over +25%' },
        { label: 'Over +30%', value: 'Over +30%' },
        { label: 'Over +35%', value: 'Over +35%' },
        { label: 'Over +40%', value: 'Over +40%' },
        { label: 'Over +45%', value: 'Over +45%' },
        { label: 'Over +50%', value: 'Over +50%' }
    ],
    currentRatio: [
        { label: 'Any', value: 'Any'},
        { label: 'High (>3)', value: 'High (>3)' },
        { label: 'Low (<1)', value: 'Low (<1)'},
        { label: 'Under 1', value: 'Under 1'},
        { label: 'Under 0.5', value: 'Under 0.5' },
        { label: 'Over 0.5', value: 'Over 0.5' },
        { label: 'Over 1', value: 'Over 1' },
        { label: 'Over 1.5', value: 'Over 1.5' },
        { label: 'Over 2', value: 'Over 2' },
        { label: 'Over 3', value: 'Over 3' },
        { label: 'Over 4', value: 'Over 4' },
        { label: 'Over 5', value: 'Over 5' },
        { label: 'Over 10', value: 'Over 10' }
    ],
    quickRatio: [
        { label: 'Any', value: 'Any'},
        { label: 'High (>3)', value: 'High (>3)' },
        { label: 'Low (<1)', value: 'Low (<1)'},
        { label: 'Under 1', value: 'Under 1'},
        { label: 'Under 0.5', value: 'Under 0.5' },
        { label: 'Over 0.5', value: 'Over 0.5' },
        { label: 'Over 1', value: 'Over 1' },
        { label: 'Over 1.5', value: 'Over 1.5' },
        { label: 'Over 2', value: 'Over 2' },
        { label: 'Over 3', value: 'Over 3' },
        { label: 'Over 4', value: 'Over 4' },
        { label: 'Over 5', value: 'Over 5' },
        { label: 'Over 10', value: 'Over 10' }
    ],
    debtToEquityRatio: [
        { label: 'Any', value: 'Any'},
        { label: 'High (>3)', value: 'High (>3)' },
        { label: 'Low (<1)', value: 'Low (<1)'},
        { label: 'Under 1', value: 'Under 1'},
        { label: 'Under 0.5', value: 'Under 0.5' },
        { label: 'Over 0.5', value: 'Over 0.5' },
        { label: 'Over 1', value: 'Over 1' },
        { label: 'Over 1.5', value: 'Over 1.5' },
        { label: 'Over 2', value: 'Over 2' },
        { label: 'Over 3', value: 'Over 3' },
        { label: 'Over 4', value: 'Over 4' },
        { label: 'Over 5', value: 'Over 5' },
        { label: 'Over 10', value: 'Over 10' }
    ],
    debtToEquityRatioLongTerm: [
        { label: 'Any', value: 'Any'},
        { label: 'High (>3)', value: 'High (>3)' },
        { label: 'Low (<1)', value: 'Low (<1)'},
        { label: 'Under 1', value: 'Under 1'},
        { label: 'Under 0.5', value: 'Under 0.5' },
        { label: 'Over 0.5', value: 'Over 0.5' },
        { label: 'Over 1', value: 'Over 1' },
        { label: 'Over 1.5', value: 'Over 1.5' },
        { label: 'Over 2', value: 'Over 2' },
        { label: 'Over 3', value: 'Over 3' },
        { label: 'Over 4', value: 'Over 4' },
        { label: 'Over 5', value: 'Over 5' },
        { label: 'Over 10', value: 'Over 10' }
    ],
    grossMargin: [
        { label: 'Any', value: 'Any'},
        { label: 'Positive (>0%)', value: 'Positive (>0%)' },
        { label: 'Negative (<0%)', value: 'Negative (<0%)'},
        { label: 'High (>50%)', value: 'High (>50%)'},
        { label: 'Under 90%', value: 'Under 90%'},
        { label: 'Under 80%', value: 'Under 80%'},
        { label: 'Under 70%', value: 'Under 70%' },
        { label: 'Under 60%', value: 'Under 60%' },
        { label: 'Under 50%', value: 'Under 50%' },
        { label: 'Under 45%', value: 'Under 45%' },
        { label: 'Under 40%', value: 'Under 40%'},
        { label: 'Under 35%', value: 'Under 35%'},
        { label: 'Under 30%', value: 'Under 30%' },
        { label: 'Under 25%', value: 'Under 25%' },
        { label: 'Under 20%', value: 'Under 20%' },
        { label: 'Under 15%', value: 'Under 15%' },
        { label: 'Under 10%', value: 'Under 10%'},
        { label: 'Under 5%', value: 'Under 5%'},
        { label: 'Under 0%', value: 'Under 0%' },
        { label: 'Under -10%', value: 'Under -10%' },
        { label: 'Under -20%', value: 'Under -20%' },
        { label: 'Under -30%', value: 'Under -30%' },
        { label: 'Under -50%', value: 'Under -50%' },
        { label: 'Under -70%', value: 'Under -70%' },
        { label: 'Under -100%', value: 'Under -100%' },
        { label: 'Over 0%', value: 'Over 0%' },
        { label: 'Over 5%', value: 'Over 5%' },
        { label: 'Over 10%', value: 'Over 10%' },
        { label: 'Over 15%', value: 'Over 15%' },
        { label: 'Over 20%', value: 'Over 20%' },
        { label: 'Over 25%', value: 'Over 25%' },
        { label: 'Over 30%', value: 'Over 30%' },
        { label: 'Over 35%', value: 'Over 35%' },
        { label: 'Over 40%', value: 'Over 40%' },
        { label: 'Over 45%', value: 'Over 45%' },
        { label: 'Over 50%', value: 'Over 50%' },
        { label: 'Over 60%', value: 'Over 60%' },
        { label: 'Over 70%', value: 'Over 70%' },
           { label: 'Over 80%', value: 'Over 80%' },
        { label: 'Over 90%', value: 'Over 90%' }
    ],
    operatingMargin: [
        { label: 'Any', value: 'Any'},
        { label: 'Positive (>0%)', value: 'Positive (>0%)' },
        { label: 'Negative (<0%)', value: 'Negative (<0%)'},
        { label: 'High (>50%)', value: 'High (>50%)'},
        { label: 'Under 90%', value: 'Under 90%'},
        { label: 'Under 80%', value: 'Under 80%'},
        { label: 'Under 70%', value: 'Under 70%' },
        { label: 'Under 60%', value: 'Under 60%' },
        { label: 'Under 50%', value: 'Under 50%' },
        { label: 'Under 45%', value: 'Under 45%' },
        { label: 'Under 40%', value: 'Under 40%'},
        { label: 'Under 35%', value: 'Under 35%'},
        { label: 'Under 30%', value: 'Under 30%' },
        { label: 'Under 25%', value: 'Under 25%' },
        { label: 'Under 20%', value: 'Under 20%' },
        { label: 'Under 15%', value: 'Under 15%' },
        { label: 'Under 10%', value: 'Under 10%'},
        { label: 'Under 5%', value: 'Under 5%'},
        { label: 'Under 0%', value: 'Under 0%' },
        { label: 'Under -10%', value: 'Under -10%' },
        { label: 'Under -20%', value: 'Under -20%' },
        { label: 'Under -30%', value: 'Under -30%' },
        { label: 'Under -50%', value: 'Under -50%' },
        { label: 'Under -70%', value: 'Under -70%' },
        { label: 'Under -100%', value: 'Under -100%' },
        { label: 'Over 0%', value: 'Over 0%' },
        { label: 'Over 5%', value: 'Over 5%' },
        { label: 'Over 10%', value: 'Over 10%' },
        { label: 'Over 15%', value: 'Over 15%' },
        { label: 'Over 20%', value: 'Over 20%' },
        { label: 'Over 25%', value: 'Over 25%' },
        { label: 'Over 30%', value: 'Over 30%' },
        { label: 'Over 35%', value: 'Over 35%' },
        { label: 'Over 40%', value: 'Over 40%' },
        { label: 'Over 45%', value: 'Over 45%' },
        { label: 'Over 50%', value: 'Over 50%' },
        { label: 'Over 60%', value: 'Over 60%' },
        { label: 'Over 70%', value: 'Over 70%' },
           { label: 'Over 80%', value: 'Over 80%' },
        { label: 'Over 90%', value: 'Over 90%' }
    ],
    netProfitMargin: [
        { label: 'Any', value: 'Any'},
        { label: 'Positive (>0%)', value: 'Positive (>0%)' },
        { label: 'Negative (<0%)', value: 'Negative (<0%)'},
        { label: 'High (>50%)', value: 'High (>50%)'},
        { label: 'Under 90%', value: 'Under 90%'},
        { label: 'Under 80%', value: 'Under 80%'},
        { label: 'Under 70%', value: 'Under 70%' },
        { label: 'Under 60%', value: 'Under 60%' },
        { label: 'Under 50%', value: 'Under 50%' },
        { label: 'Under 45%', value: 'Under 45%' },
        { label: 'Under 40%', value: 'Under 40%'},
        { label: 'Under 35%', value: 'Under 35%'},
        { label: 'Under 30%', value: 'Under 30%' },
        { label: 'Under 25%', value: 'Under 25%' },
        { label: 'Under 20%', value: 'Under 20%' },
        { label: 'Under 15%', value: 'Under 15%' },
        { label: 'Under 10%', value: 'Under 10%'},
        { label: 'Under 5%', value: 'Under 5%'},
        { label: 'Under 0%', value: 'Under 0%' },
        { label: 'Under -10%', value: 'Under -10%' },
        { label: 'Under -20%', value: 'Under -20%' },
        { label: 'Under -30%', value: 'Under -30%' },
        { label: 'Under -50%', value: 'Under -50%' },
        { label: 'Under -70%', value: 'Under -70%' },
        { label: 'Under -100%', value: 'Under -100%' },
        { label: 'Over 0%', value: 'Over 0%' },
        { label: 'Over 5%', value: 'Over 5%' },
        { label: 'Over 10%', value: 'Over 10%' },
        { label: 'Over 15%', value: 'Over 15%' },
        { label: 'Over 20%', value: 'Over 20%' },
        { label: 'Over 25%', value: 'Over 25%' },
        { label: 'Over 30%', value: 'Over 30%' },
        { label: 'Over 35%', value: 'Over 35%' },
        { label: 'Over 40%', value: 'Over 40%' },
        { label: 'Over 45%', value: 'Over 45%' },
        { label: 'Over 50%', value: 'Over 50%' },
        { label: 'Over 60%', value: 'Over 60%' },
        { label: 'Over 70%', value: 'Over 70%' },
           { label: 'Over 80%', value: 'Over 80%' },
        { label: 'Over 90%', value: 'Over 90%' }
    ],
    payoutRatio: [
        { label: 'Any', value: 'Any'},
        { label: 'None (0%)', value: 'None (0%)' },
        { label: 'Positive (>0%)', value: 'Positive (>0%)' },
        { label: 'Low (<20%)', value: 'Low (<20%)'},
        { label: 'High (>50%)', value: 'High (>50%)'},
        { label: 'Over 0%', value: 'Over 0%' },
        { label: 'Over 5%', value: 'Over 5%' },
        { label: 'Over 10%', value: 'Over 10%' },
        { label: 'Over 15%', value: 'Over 15%' },
        { label: 'Over 20%', value: 'Over 20%' },
        { label: 'Over 25%', value: 'Over 25%' },
        { label: 'Over 30%', value: 'Over 30%' },
        { label: 'Over 35%', value: 'Over 35%' },
        { label: 'Over 40%', value: 'Over 40%' },
        { label: 'Over 45%', value: 'Over 45%' },
        { label: 'Over 50%', value: 'Over 50%' },
        { label: 'Over 60%', value: 'Over 60%' },
        { label: 'Over 70%', value: 'Over 70%' },
           { label: 'Over 80%', value: 'Over 80%' },
        { label: 'Over 90%', value: 'Over 90%' },
        { label: 'Over 100%', value: 'Over 100%' },
        { label: 'Under 10%', value: 'Under 10%'},
        { label: 'Under 20%', value: 'Under 20%'},
        { label: 'Under 30%', value: 'Under 30%' },
        { label: 'Under 40%', value: 'Under 40%' },
        { label: 'Under 50%', value: 'Under 50%' },
        { label: 'Under 60%', value: 'Under 60%' },
        { label: 'Under 70%', value: 'Under 70%'},
        { label: 'Under 80%', value: 'Under 80%'},
        { label: 'Under 90%', value: 'Under 90%' },
        { label: 'Under 100%', value: 'Under 100%' }    
    ]
};