<p>image-popup works!</p>
<div *ngIf="showPopup" class="popup">
<div id="openModal" class="modalDialog">
    <div> <a href="#" title="Close" class="close">X</a>
  
        <a href="https://trucharts.com/subscript"> <img src="../../../assets/dist/img/trucharts.jpg" alt="Image popup" class="popupimg"/> </a> 
    </div>
  </div>
</div>
<!-- <div *ngIf="showPopup" class="popup">
  
    <a href="https://trucharts.com/subscript"> <img src="../../../assets/dist/img/trucharts.jpg" alt="Image popup" class="popupimg"/> </a> 
     
     
    <button (click)="closePopup()">Close</button>
  </div>
  
    -->