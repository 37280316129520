<app-header>
</app-header>


<div>
<div>
<div style="float:left;margin-left:5%;width:90%;text-align:justify;margin-right:5%">
<div style="font-size:14px;width:110px; font-weight:bold;margin-top:30px;float:left">Terms Of Use</div><div style="float:left;width:250px;margin-top:30px">- Applicable to all visitors to our site. </div>
<br />
<br />

<div style="width:100%;float:left;margin-top:30px">

Warning: Legalese ahead. This is the most important info on the site apart from what we offer to our end users. We've added some non-binding, non-legalistic translations between the paragraphs. 
<br />
<br />
<b>AGREEMENT.</b> This website, "truCharts.com", is owned and operated by truCharts.com, Inc. and is offered to you conditioned on your acceptance without modification of the terms, conditions and notices contained herein. By accessing and using this website, you are deemed to have agreed to all such terms, conditions, and notices. 
<br />
<br />

<b>ACCURACY OF INFORMATION.</b> . All of the information on this website is for entertainment and educational purposes only. While the information is believed to be accurate and the analysis is honestly offered, none of the information on this site should be considered solely reliable for use in making actual investment decisions. truCharts.com is a distributor (and not a publisher) of content supplied by third parties. None of the information on this website is guaranteed to be accurate, complete, useful or timely. truCharts.com, Inc. and its third-party providers do not warrant or guarantee such accuracy, completeness, usefulness or timelines. truCharts.com neither endorses nor is responsible for the accuracy or reliability of any opinion, advice or statement made on truCharts.com by anyone other than authorized truCharts.com employees or spokespersons while acting in their official capacities. truCharts.com, Inc. may make improvements and/or changes in the Information and/or the services described herein at any time. And while it is unlikely, this website may cease publication at any time. Periodic updates to this site are not guaranteed. Meaning: The people giving us our information don't guarantee its accuracy so neither can we. And the programs we use to create our charts aren't guaranteed to be bug-free either.  However, we are confident that our charts are as accurate as anything else out there. 
<br />
<br />
<b>
DISCLAIMER.</b> truCharts.com, INC. AND ITS THIRD-PARTY PROVIDERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE SERVICES OR INFORMATION CONTAINED ON THIS WEBSITE FOR ANY PURPOSE. ALL SUCH SERVICES AND INFORMATION ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. truCharts.com, INC. HEREBY DISCLAIMS ALL WARRANTIES WITH REGARD TO THE SERVICES AND INFORMATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT OTHER THAN THOSE WARRANTIES WHICH ARE IMPLIED BY AND INCAPABLE OF EXCLUSION, RESTRICTION OR MODIFICATION UNDER THE LAWS APPLICABLE TO THIS AGREEMENT. truCharts.com, INC. DOES NOT WARRANT THAT THE INFORMATION WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. Translation: This is a secret message to other lawyers saying "Back Off!" …or so we are told. 
<br />
<br />
<b>
LIMITATION OF LIABILITY.</b> Under no circumstances shall truCharts.com, Inc., its officers, directors, shareholders agents or its third-party providers be liable for any direct, indirect, incidental, punitive, special or consequential damages (including without limitation, attorneys' fees), whether in an action of contract, negligence or other torturous action, that result from the use of, or the inability to use, any materials available on this website, even if truCharts.com, Inc. has been advised of such damages. If you are dissatisfied with any truCharts.com Information or other materials, or with any of the terms and conditions contained in this website, your sole and exclusive remedy is to discontinue using truCharts.com. If truCharts.com, Inc. or any of its third-party providers are found liable in connection with a claim arising out of or related to the services or the Information, their aggregate liability in such an event shall not exceed the amount of the fees paid by you for use of the service during the month in which the event giving rise to the liability occurred. Your right to monetary damages in such amount shall be in lieu of all other remedies to which you may otherwise be entitled from truCharts.com, Inc. or its third-party providers. Because some states/jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental damages, the above limitation may not apply to you. Meaning: We are only liable for as much money as you give us. 
<br />
<br />
<b>
INVESTMENT DECISIONS.</b> You assume all risk associated with investment decisions made on the basis of information contained on this website. It is our policy to never advocate the purchase or sale of any individual investment vehicle. You should also understand that truCharts.com, it's third-party providers and their respective officers, directors and employees may be active investors in the market and may or may not have open positions in any investment vehicle mentioned on this website. Prior to the execution of a stock trade, you are advised to consult with your broker or other financial representative to verify pricing and other information. truCharts.com, Inc., it's third-party providers, or content partners shall have no liability for investment decisions based upon the Information. Translation: You are responsible for your own investment decisions. Not us. Not anyone else. It is YOU. 
<br />
<br />
<b>
RESTRICTIONS ON USE.</b> All materials published by truCharts.com, Inc., including, but not limited to charts, images, illustrations, price information, reviews and email newsletters ("Information") are protected by copyright, and owned or controlled by truCharts.com, Inc. or it's third-party providers. You must access the information manually by request and not programmatically by macro or other automated means. You may not use the contents for any unlawful purpose. 


<br />
<br />
<b>
LIMITED LICENSE.</b> Permission to use the Information available from this truCharts.com website is granted, provided that (1) the copyright notice below appears in all copies and that both the copyright notice and this permission notice appear, (2) use of Information available from this truCharts.com website is for informational and non-commercial or personal use only, and (3) no Information available from this truCharts.com website is modified in any way. Except for the specified uses, no part of the Information available from this truCharts.com website may be copied, photocopied, reproduced, republished, licensed, distributed, performed, displayed, uploaded, posted, translated, altered, retransmitted, broadcast in any way, exploited, used to create derivative works, or reduced to any electronic medium or machine-readable form, in whole or in part, without the prior written consent of truCharts.com, Inc. or its third-party providers. 
<br />
<br />
<b>
ANY DISTRIBUTION FOR COMMERCIAL PURPOSES IS STRICTLY PROHIBITED.</b><br />
<br />
<b>
Translation:</b> Don't even think about trying to sell any of the charts, data without express written permission of truCharts.com. 
<br />
<br />
<b>
LINKS:</b>We encourage web page authors to link to any of the public HTML pages on this website, however external links to individual charts, graphics, data files or non-public HTML files on this website are prohibited. truCharts.com may contain links to other websites that may be of interest to the reader. truCharts.com has no control over nor responsibility for the content found on those websites or their accessibility. Meaning: Link to our pages, NOT our charts! 
<br />
<br />
<b>
COPYRIGHT NOTICE.</b> All content on this site is Copyright ©1999-2012 by truCharts.com Inc., Houston. Texas All rights reserved. 
<br />
<br />
<b>
TRADEMARKS.</b> truCharts.com™ is a trademark of truCharts.com, Inc. Other product and company names mentioned herein may be the trademarks of their respective owners. 
<br />
<br />
<b>
MODIFICATION OF THESE TERMS AND CONDITIONS.</b> . truCharts.com, Inc. reserves the right to change this Terms of Service agreement at any time by revising the terms and conditions herein, including, but not limited to, adding fees and charges for use. You are responsible for regularly reviewing these terms and conditions. Such changes, modifications, additions or deletions shall be effective immediately upon notice thereof, which may be given by means including, but not limited to, posting on truCharts.com website, or by electronic or conventional mail. Continued use of this truCharts.com website following any such changes shall constitute your acceptance of such changes. Meaning: Always re-read this page before doing anything out of the ordinary with our site. 
<br />
<br />
<b>
GENERAL.</b>This agreement is governed by the laws of the State of Texas, U.S.A. You consent to the exclusive jurisdiction and venue of courts in Harris County, Texas, U.S.A. in all disputes arising out of or relating to the use of this truCharts.com website. Use of this truCharts.com website is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions, including without limitation this paragraph. Meaning - You have to fly to Houston, Texas if you want to sue us. 
<br />
<br />
<b>
SEVERABILITY.</b> If any part of this Agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the Agreement shall continue in effect. Meaning - A typographical error doesn't invalidate this agreement. 
<br />
<br />
<b>
ENTIRE AGREEMENT.</b> This agreement constitutes the entire agreement between you and truCharts.com, Inc. with respect to this truCharts.com website and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and truCharts.com, Inc. with respect to this website. A printed version of this agreement and of any notice given in electronic form will be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. Meaning -  Although it looks like a computer screen, you are looking at an official - and very long - document. Contact us if you have any questions about this agreement @ <a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="4e2f2a2327200e3a3c3b0d262f3c3a3d602d212360">admin@truCharts.com</a> 




<br />
<br />
<br />

</div>
</div>
</div>
</div>


<div style="min-height: 2200px;"></div>
<app-footer>
</app-footer>