<div class="container">
    <div class="row">
       <div class="col-md-6 mx-auto mt-5 mb-5 pt-3 pb-3">
          <div class="payment">
             <div class="payment_header">
                <div class="check"><i class="fa fa-3x fa-check" aria-hidden="true" style="color:green;"></i></div>
             </div>
             <div class="content">

                <h3 class="text-bold">Success</h3>
                <h2> Congratulations</h2>
                <p>Welcome to Trucharts.com. Your subscription is successful and now you can access the site.Thank you!</p>
                <br>
                <!-- <h4>Transaction Id</h4> -->
                <!-- <p>if you are not redirected in 10 seconds .. please click the below link</p> -->

                <button routerLink="/" class="btn btn-default card-button" >Click Here</button>
             </div>

          </div>
       </div>
    </div>
 </div>
