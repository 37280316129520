<app-header>
</app-header>

<div style="float:left;margin-left:5%;width:90%;text-align:justify;margin-right:5%; margin-top: 50px;margin-bottom: 50px;">
    <h3>
        Privacy Policy For Infromation Use at TruCharts.com</h3>
    <div class="content">
        <p>
            TruCharts.com, ("We") are committed to maintaining the privacy of personal information
            that you provide to us when using the trucharts.com web site. This privacy policy
            describes how we treat personal information received about you when you visit www.trucharts.com.
        </p>
        <p>
            The personally information is stored in our limited-access servers. We will maintain
            safeguards to protect the security of these servers and your personally identifiable
            information. Online purchases made through our web site are processed securely by
            SercurePay.com. When making a payment, we do not have access to your personal and
            credit card information.
        </p>
        <p>
            We may make content or services from other web sites including our co-branded web
            sites available to you from links located on www.trucharts.com. These other web
            sites are not subject to this privacy policy. We recommend that you review the privacy
            policy at each such web site to determine how that site protects your privacy.
        </p>
        <p>
            "Cookies" are pieces of information that a browser can record after visiting a web
            site. We may use cookies for technical purposes such as to enable better navigation
            through our site, or to store user preferences for interacting with the site. If
            you do not wish to have cookies stored on your machine, you can turn them off in
            your browser, it will not affect the overall functioning of the web site
        </p>
        <p>
            Given that the internet is a global environment, using the internet to collect and
            process personal data necessarily involves the transmission of data on an international
            basis. Therefore, by browsing www.TruCharts.com and communicating electronically
            with us you acknowledge and agree to our processing of personal data in this way.
        </p>
        <p>
            If you have any questions, comments or concerns about our privacy policy,
            <a id="ctl00_ContentPlaceHolder1_HyperLink1" href="https://trucharts.com/contact" style="color:#6FB7FF;text-decoration:none;">CONTACT US</a>.
        </p>
      
    </div>
</div>
<div style="min-height: 1200px;"></div>
<app-footer>
</app-footer>