<div class="card shadow-none bg-transparent">
    <div class="card-body text-center p-3 p-lg5">
        <div class="row align-center">
            <div class="col-12">
                <h3 class="text-muted">Pick a stock using our Technical Stock Report</h3>
            </div>
            <div class="col-12 col-lg-6">
                <form class="form-inline mt-3">
                    <div class="input-group input-group-lg w-100">
                        <div class="row w-100 m-0">
                            <div class="col-12 col-lg-8 mb-2 mb-lg-0">
                                <p-dropdown [options]="technicals" styleClass="p-2 w-100 " class="w-50">
                                </p-dropdown>
                            </div>
                            <div class="col-12 col-lg-4">
                                <a [routerLink]="['/market-report']" class="btn btn-lg btn-block btn-info h-100">Get Report</a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-12">
                <h5 class="text-muted mt-3">or</h5>
            </div>
            <div class="col-12">
                <h6 class="text-muted mt-2">Analyze a stock using customized 
                    <a [routerLink]="['/screener']">Dynamic Technical Scanner</a>
                </h6>
            </div>
        </div>
    </div>
</div>
