<div class="row">
  <div class="col-12 col-lg-6 col-xl-6">
    <app-top-gainer-table iconTitle="fa-arrow-up" status="up" title="Top Gainers" [tableData]="topGainers"></app-top-gainer-table>
  </div>
  <div class="col-12 col-lg-6 col-xl-6">
    <app-top-gainer-table iconTitle="fa-arrow-down" status="down" title="Top Losers" [tableData]="topLoosers"></app-top-gainer-table>
  </div>
  <!-- <div class="col-12 col-lg-6 col-xl-4">
    <app-top-gainer-table iconTitle="fa-chart-line" status="neutral" title="Most Active" [tableData]="topActives"></app-top-gainer-table>
  </div> -->
</div>

<!-- <section class="site-tour p-0 pb-3 d-none d-md-block">
  <div class="row">
    <div class="col-12 col-lg-6 col-xl-4">
      <div class="card shadow-element">
        <div class="card-body">
          <app-video [idToLoad]="0"></app-video>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 col-xl-4">
      <div class="card shadow-element">
        <div class="card-body">
          <app-video [idToLoad]="1"></app-video>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 col-xl-4">
      <div class="card shadow-element">
        <div class="card-body">
          <app-video [idToLoad]="2"></app-video>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="site-tour p-0 pb-3 d-block d-md-none">
  <div class="row">
    <div class="col-12">
      <div class="card shadow-element">
        <div class="card-body">
          <app-video [idToLoad]="0"></app-video>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card shadow-element">
        <div class="card-body">
          <app-video [idToLoad]="1"></app-video>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card shadow-element">
        <div class="card-body">
          <app-video [idToLoad]="2"></app-video>
        </div>
      </div>
    </div>
  </div>
</section> -->