<!-- ==== Footer Area :: Start ==== -->
<footer>
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="footer--box logo--box">
          <div class="logo--and--social">
            <a href="index.html">
              <img src="assets/new-layout/img/logo-white.webp" alt="img" />
            </a>
            <div class="social--icon">
              <a href="https://x.com/trucharts">
                <i class="bx bxl-twitter"></i>
              </a>
              <a href="https://www.youtube.com/@Trucharts">
                <i class="bx bxl-youtube"></i>
              </a>
            </div>
          </div>
          <p class="mt_30">
            The information contained in this website is for general information
            purposes only. The information is provided by truCharts.com and
            while we endeavour to keep the information up to date and correct,
            we make no representations or warranties of any kind, express or
            implied, about the completeness, accuracy, reliability, suitability
            or availability with respect to the website or the information,
            products, services, or related graphics contained on the website for
            any purpose. Any reliance you place on such information is therefore
            strictly at your own risk. In no event will we be liable for any
            loss or damage including without limitation, indirect or
            consequential loss or damage, or any loss or damage whatsoever
            arising from loss of data or profits arising out of, or in
            connection with, the use of this website.
          </p>
        </div>
      </div>
      <div class="col-md-2 col-sm-6">
        <div class="footer--box">
          <ul class="quick--links">
            <li>
              <a href="subscript">Pricing</a>
            </li>
            <li>
              <a href="contact">Contact us</a>
            </li>
            <li>
              <a href="Privacy">Privacy</a>
            </li>
            <li>
              <a href="Terms">Terms of Use</a>
            </li>

            <!-- <li>
							<a href="login">Log In</a>
						</li> -->
          </ul>
        </div>
      </div>
      <div class="col-md-2 col-sm-6">
        <div class="footer--box">
          <ul class="quick--links">
            <li>
              <a [routerLink]="['/stockchart', 'TSLA']">Stockcharts</a>
            </li>
            <li>
              <a href="screener">Screener</a>
            </li>
            <li>
              <a href="market-report">Market Reports</a>
            </li>
            <li>
              <a href="heatmap">Heatmap</a>
            </li>
            <li>
              <a href="charts">Multi Charts</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright--area">
    <p>© 2023 www.trucharts.com</p>
  </div>
</footer>
<!-- ==== Footer Area :: End ==== -->
<!-- ==== Back To Top Area :: Start ==== -->
<!-- <a href="#" class="back-to-top">
	<i class="bx bx-chevron-up"></i>
</a> -->
<!-- ==== Back To Top Area :: End ==== -->
