export const TechnicalData = {
    movingAverage20day : [
        {label: 'Any', value: 'Any'},
        {label: 'Price below SMA20', value: 'Price below SMA20'},
        {label: 'Price 10% below SMA20', value: 'Price 10% below SMA20'},
        {label: 'Price 20% below SMA20', value: 'Price 20% below SMA20'},
        {label: 'Price 30% below SMA20', value: 'Price 30% below SMA20'},
        {label: 'Price 40% below SMA20', value: 'Price 40% below SMA20'},
        {label: 'Price 50% below SMA20', value: 'Price 50% below SMA20'},
        {label: 'Price above SMA20', value: 'Price above SMA20'},
        {label: 'Price 10% above SMA20', value: 'Price 10% above SMA20'},
        {label: 'Price 20% above SMA20', value: 'Price 20% above SMA20'},
        {label: 'Price 30% above SMA20', value: 'Price 30% above SMA20'},
        {label: 'Price 40% above SMA20', value: 'Price 40% above SMA20'},
        {label: 'Price 50% above SMA20', value:  'Price 50% above SMA20'},
        {label: 'Price crossed SMA20 above', value: 'Price crossed SMA20 above'},
        {label: 'Price crossed SMA20 below', value: 'Price crossed SMA20 below'},
        {label: 'SMA20 crossed SMA50 above', value: 'SMA20 crossed SMA50 above'},
        {label: 'SMA20 crossed SMA50 below', value: 'SMA20 crossed SMA50 below'}
    ],
    movingAverage50day: [
        {label: 'Any', value: 'Any'},
        {label: 'Price below SMA50', value: 'Price below SMA50'},
        {label: 'Price 10% below SMA50', value: 'Price 10% below SMA50'},
        {label: 'Price 20% below SMA50', value: 'Price 20% below SMA50'},
        {label: 'Price 30% below SMA50', value: 'Price 30% below SMA50'},
        {label: 'Price 40% below SMA50', value: 'Price 40% below SMA50'},
        {label: 'Price 50% below SMA50', value: 'Price 50% below SMA50'},
        {label: 'Price above SMA50', value: 'Price above SMA50'},
        {label: 'Price 10% above SMA50', value: 'Price 10% above SMA50'},
        {label: 'Price 20% above SMA50', value: 'Price 20% above SMA50'},
        {label: 'Price 30% above SMA50', value: 'Price 30% above SMA50'},
        {label: 'Price 40% above SMA50', value: 'Price 40% above SMA50'},
        {label: 'Price 50% above SMA50', value: 'Price 50% above SMA50'}
    ],
    movingAverage120day: [
        {label: 'Any', value: 'Any'},
        {label: 'Price below SMA120', value: 'Price below SMA120'},
        {label: 'Price 10% below SMA120', value: 'Price 10% below SMA120'},
        {label: 'Price 20% below SMA120', value: 'Price 20% below SMA120'},
        {label: 'Price 30% below SMA120', value: 'Price 30% below SMA120'},
        {label: 'Price 40% below SMA120', value: 'Price 40% below SMA120'},
        {label: 'Price 50% below SMA120', value: 'Price 50% below SMA120'},
        {label: 'Price above SMA120', value: 'Price above SMA120'},
        {label: 'Price 10% above SMA120', value: 'Price 10% above SMA120'},
        {label: 'Price 20% above SMA120', value: 'Price 20% above SMA120'},
        {label: 'Price 30% above SMA120', value: 'Price 30% above SMA120'},
        {label: 'Price 40% above SMA120', value: 'Price 40% above SMA120'},
        {label: 'Price 50% above SMA120', value: 'Price 50% above SMA120'}
    ],
    exponentialMovingAverage20day: [
        {label: 'Any', value: 'Any'},
        {label: 'Price below EMA20', value: 'Price below EMA20'},
        {label: 'Price 10% below EMA20', value: 'Price 10% below EMA20'},
        {label: 'Price 20% below EMA20', value: 'Price 20% below EMA20'},
        {label: 'Price 30% below EMA20', value: 'Price 30% below EMA20'},
        {label: 'Price 40% below EMA20', value: 'Price 40% below EMA20'},
        {label: 'Price 50% below EMA20', value: 'Price 50% below EMA20'},
        {label: 'Price above EMA20', value: 'Price above EMA20'},
        {label: 'Price 10% above EMA20', value: 'Price 10% above EMA20'},
        {label: 'Price 20% above EMA20', value: 'Price 20% above EMA20'},
        {label: 'Price 30% above EMA20', value: 'Price 30% above EMA20'},
        {label: 'Price 40% above EMA20', value: 'Price 40% above EMA20'},
        {label: 'Price 50% above EMA20', value: 'Price 50% above EMA20'}
    ],
    exponentialMovingAverage50day: [
        {label: 'Any', value: 'Any'},
        {label: 'Price below EMA50', value: 'Price below EMA50'},
        {label: 'Price 10% below EMA50', value: 'Price 10% below EMA50'},
        {label: 'Price 20% below EMA50', value: 'Price 20% below EMA50'},
        {label: 'Price 30% below EMA50', value: 'Price 30% below EMA50'},
        {label: 'Price 40% below EMA50', value: 'Price 40% below EMA50'},
        {label: 'Price 50% below EMA50', value: 'Price 50% below EMA50'},
        {label: 'Price above EMA50', value: 'Price above EMA50'},
        {label: 'Price 10% above EMA50', value: 'Price 10% above EMA50'},
        {label: 'Price 20% above EMA50', value: 'Price 20% above EMA50'},
        {label: 'Price 30% above EMA50', value: 'Price 30% above EMA50'},
        {label: 'Price 40% above EMA50', value: 'Price 40% above EMA50'},
        {label: 'Price 50% above EMA50', value: 'Price 50% above EMA50'}
    ],
    exponentialMovingAverage120day: [
        {label: 'Any', value: 'Any'},
        {label: 'Price below EMA120', value: 'Price below EMA120'},
        {label: 'Price 10% below EMA120', value: 'Price 10% below EMA120'},
        {label: 'Price 20% below EMA120', value: 'Price 20% below EMA120'},
        {label: 'Price 30% below EMA120', value: 'Price 30% below EMA120'},
        {label: 'Price 40% below EMA120', value: 'Price 40% below EMA120'},
        {label: 'Price 50% below EMA120', value: 'Price 50% below EMA120'},
        {label: 'Price above EMA120', value: 'Price above EMA120'},
        {label: 'Price 10% above EMA120', value: 'Price 10% above EMA120'},
        {label: 'Price 20% above EMA120', value: 'Price 20% above EMA120'},
        {label: 'Price 30% above EMA120', value: 'Price 30% above EMA120'},
        {label: 'Price 40% above EMA120', value: 'Price 40% above EMA120'},
        {label: 'Price 50% above EMA120', value: 'Price 50% above EMA120'},
    ],
    priceAction : [
        {label: 'Any', value: 'Any'},
        {label: 'Up 1 to 3%', value: 'Up 1 to 3%'},
        {label: 'Up 3 to 5%', value: 'Up 3 to 5%'},
        {label: 'Up 5 to 10%', value: 'Up 5 to 10%'},
        {label: 'Up 10 to 15%', value: 'Up 10 to 15%'},
        {label: 'Up 15 to 20%', value: 'Up 15 to 20%'},
        {label: 'Up 20 to 30%', value: 'Up 20 to 30%'},
        {label: 'Up 30 to 40%', value: 'Up 30 to 40%'},
        {label: 'Up > 40%', value: 'Up > 40%'},
        {label: 'Down 1 to 3%', value: 'Down 1 to 3%'},
        {label: 'Down 3 to 5%', value: 'Down 3 to 5%'},
        {label: 'Down 5 to 10%', value: 'Down 5 to 10%'},
        {label: 'Down 10 to 15%', value: 'Down 10 to 15%'},
        {label: 'Down 15 to 20%', value: 'Down 15 to 20%'},
        {label: 'Down 20 to 30%', value: 'Down 20 to 30%'},
        {label: 'Down 30 to 40%', value: 'Down 30 to 40%'},
        {label: 'Down > 40%', value: 'Down > 40%'}
    ],
    newHighLowPrice : [
        {label: 'Any', value: 'Any'},
        {label: 'New 52 Week High', value: 'New 52 Week High'},
        {label: 'New 26 Week High', value: 'New 26 Week High'},
        {label: 'New 13 Week High', value: 'New 13 Week High'},
        {label: 'New 4 Week High', value: 'New 4 Week High'},
        {label: 'New 52 Week Low', value: 'New 52 Week Low'},
        {label: 'New 26 Week Low', value: 'New 26 Week Low'},
        {label: 'New 13 Week Low', value: 'New 13 Week Low'},
        {label: 'New 4 Week Low', value: 'New 4 Week Low'},
        {label: 'New 52 Week Closing High', value: 'New 52 Week Closing High'},
        {label: 'New 26 Week Closing High', value: 'New 26 Week Closing High'},
        {label: 'New 13 Week Closing High', value: 'New 13 Week Closing High'},
        {label: 'New 4 Week Closing High', value: 'New 4 Week Closing High'},
        {label: 'New 52 Week Closing Low', value: 'New 52 Week Closing Low'},
        {label: 'New 26 Week Closing Low', value: 'New 26 Week Closing Low'},
        {label: 'New 13 Week Closing Low', value: 'New 13 Week Closing Low'},
        {label: 'New 4 Week Closing Low', value: 'New 4 Week Closing Low'}
    ],
    newHighLowVol: [
        {label: 'Any', value: 'Any'},
        {label: 'New 52 Week High', value: 'New 52 Week High'},
        {label: 'New 26 Week High', value: 'New 26 Week High'},
        {label: 'New 13 Week High', value: 'New 13 Week High'},
        {label: 'New 4 Week High', value: 'New 4 Week High'},
        {label: 'New 52 Week Low', value: 'New 52 Week Low'},
        {label: 'New 26 Week Low', value: 'New 26 Week Low'},
        {label: 'New 13 Week Low', value: 'New 13 Week Low'},
        {label: 'New 4 Week Low', value: 'New 4 Week Low'}
    ],
    maVspricecrosses: [
        {label: 'Any', value: 'Any'},
        {label: 'Moved Above 200 DMA in Last Trading Session', value: 'Moved Above 200 DMA in Last Trading Session'},
        {label: 'Moved Above 100 DMA in Last Trading Session', value: 'Moved Above 100 DMA in Last Trading Session'},
        {label: 'Moved Above 50 DMA in Last Trading Session', value: 'Moved Above 50 DMA in Last Trading Session'},
        {label: 'Moved Above 26 DMA in Last Trading Session', value: 'Moved Above 26 DMA in Last Trading Session'},
        {label: 'Moved Above 13 DMA in Last Trading Session', value: 'Moved Above 13 DMA in Last Trading Session'},
        {label: 'Moved Above 7 DMA in Last Trading Session', value: 'Moved Above 7 DMA in Last Trading Session'},
        {label: 'Trading Above 200 DMA', value: 'Trading Above 200 DMA'},
        {label: 'Trading Above 100 DMA', value: 'Trading Above 100 DMA'},
        {label: 'Trading Above 50 DMA', value: 'Trading Above 50 DMA'},
        {label: 'Trading Above 26 DMA', value: 'Trading Above 26 DMA'},
        {label: 'Trading Above 13 DMA', value: 'Trading Above 13 DMA'},
        {label: 'Trading Above 7 DMA', value: 'Trading Above 7 DMA'},
        {label: 'Moved Below 200 DMA in Last Trading Session', value: 'Moved Below 200 DMA in Last Trading Session'},
        {label: 'Moved Below 100 DMA in Last Trading Session', value: 'Moved Below 100 DMA in Last Trading Session'},
        {label: 'Moved Below 50 DMA in Last Trading Session', value: 'Moved Below 50 DMA in Last Trading Session'},
        {label: 'Moved Below 26 DMA in Last Trading Session', value: 'Moved Below 26 DMA in Last Trading Session'},
        {label: 'Moved Below 13 DMA in Last Trading Session', value: 'Moved Below 13 DMA in Last Trading Session'},
        {label: 'Moved Below 7 DMA in Last Trading Session', value: 'Moved Below 7 DMA in Last Trading Session'},
        {label: 'Trading Below 200 DMA', value: 'Trading Below 200 DMA'},
        {label: 'Trading Below 100 DMA', value: 'Trading Below 100 DMA'},
        {label: 'Trading Below 50 DMA', value: 'Trading Below 50 DMA'},
        {label: 'Trading Below 26 DMA', value: 'Trading Below 26 DMA'},
        {label: 'Trading Below 13 DMA', value: 'Trading Below 13 DMA'},
        {label: 'Trading Below 7 DMA', value: 'Trading Below 7 DMA'}
    ],
    emaCrossovers: [
        {label: 'Any', value: 'Any'},
        {label: 'EMA(50) Crossed Above EMA(200) in Last Trading Session', value: 'EMA(50) Crossed Above EMA(200) in Last Trading Session'},
        {label: 'EMA(26) Crossed Above EMA(200) in Last Trading Session', value: 'EMA(26) Crossed Above EMA(200) in Last Trading Session'},
        {label: 'EMA(26) Crossed Above EMA(50) in Last Trading Session', value: 'EMA(26) Crossed Above EMA(50) in Last Trading Session'},
        {label: 'EMA(13) Crossed Above EMA(200) in Last Trading Session', value: 'EMA(13) Crossed Above EMA(200) in Last Trading Session'},
        {label: 'EMA(13) Crossed Above EMA(50) in Last Trading Session', value: 'EMA(13) Crossed Above EMA(50) in Last Trading Session'},
        {label: 'EMA(13) Crossed Above EMA(26) in Last Trading Session', value: 'EMA(13) Crossed Above EMA(26) in Last Trading Session'},
        {label: 'EMA(7) Crossed Above EMA(200) in Last Trading Session', value: 'EMA(7) Crossed Above EMA(200) in Last Trading Session'},
        {label: 'EMA(7) Crossed Above EMA(50) in Last Trading Session', value: 'EMA(7) Crossed Above EMA(50) in Last Trading Session'},
        {label: 'EMA(7) Crossed Above EMA(26) in Last Trading Session', value: 'EMA(7) Crossed Above EMA(26) in Last Trading Session'},
        {label: 'EMA(7) Crossed Above EMA(13) in Last Trading Session', value: 'EMA(7) Crossed Above EMA(13) in Last Trading Session'},
        {label: 'EMA(50) Above EMA(200)', value: 'EMA(50) Above EMA(200)'},
        {label: 'EMA(26) Above EMA(200)', value: 'EMA(26) Above EMA(200)'},
        {label: 'EMA(26) Above EMA(50)', value: 'EMA(26) Above EMA(50)'},
        {label: 'EMA(13) Above EMA(50)', value: 'EMA(13) Above EMA(50)'},
        {label: 'EMA(13) Above EMA(26)', value: 'EMA(13) Above EMA(26)'},
        {label: 'EMA(7) Above EMA(200)', value: 'EMA(7) Above EMA(200)'},
        {label: 'EMA(7) Above EMA(50)', value: 'EMA(7) Above EMA(50)'},
        {label: 'EMA(7) Above EMA(26)', value: 'EMA(7) Above EMA(26)'},
        {label: 'EMA(7) Above EMA(13)', value: 'EMA(7) Above EMA(13)'},
        {label: 'EMA(50) Crossed Below EMA(200) in Last Trading Session', value: 'EMA(50) Crossed Below EMA(200) in Last Trading Session'},
        {label: 'EMA(7) Crossed Below EMA(50) in Last Trading Session', value: 'EMA(7) Crossed Below EMA(50) in Last Trading Session'},
        {label: 'EMA(13) Crossed Below EMA(200) in Last Trading Session', value: 'EMA(13) Crossed Below EMA(200) in Last Trading Session'},
        {label: 'EMA(7) Crossed Below EMA(26) in Last Trading Session', value: 'EMA(7) Crossed Below EMA(26) in Last Trading Session'},
        {label: 'EMA(26) Crossed Below EMA(50) in Last Trading Session', value: 'EMA(26) Crossed Below EMA(50) in Last Trading Session'},
        {label: 'EMA(13) Crossed Below EMA(26) in Last Trading Session', value: 'EMA(13) Crossed Below EMA(26) in Last Trading Session'},
        {label: 'EMA(13) Crossed Below EMA(50) in Last Trading Session', value: 'EMA(13) Crossed Below EMA(50) in Last Trading Session'},
        {label: 'EMA(26) Crossed Below EMA(200) in Last Trading Session', value: 'EMA(26) Crossed Below EMA(200) in Last Trading Session'},
        {label: 'EMA(7) Crossed Below EMA(13) in Last Trading Session', value: 'EMA(7) Crossed Below EMA(13) in Last Trading Session'},
        {label: 'EMA(7) Crossed Below EMA(200) in Last Trading Session', value: 'EMA(7) Crossed Below EMA(200) in Last Trading Session'},
        {label: 'EMA(13) Below EMA(50)', value: 'EMA(13) Below EMA(50)'},
        {label: 'EMA(13) Below EMA(200)', value: 'EMA(13) Below EMA(200)'},
        {label: 'EMA(7) Below EMA(13)', value: 'EMA(7) Below EMA(13)'},
        {label: 'EMA(26) Below EMA(50)', value: 'EMA(26) Below EMA(50)'},
        {label: 'EMA(7) Below EMA(50)', value: 'EMA(7) Below EMA(50)'},
        {label: 'EMA(26) Below EMA(200)', value: 'EMA(26) Below EMA(200)'},
        {label: 'EMA(7) Below EMA(200)', value: 'EMA(7) Below EMA(200)'},
        {label: 'EMA(7) Below EMA(26)', value: 'EMA(7) Below EMA(26)'},
    ],
    volume: [
        {label: 'Any', value: 'Any'},
        {label: '1-50000', value: '1-50000'},
        {label: '50000-100000', value: '50000-100000'},
        {label: '100000-500000', value: '100000-500000'},
        {label: '500000-1000000', value: '500000-1000000'},
        {label: '1000000-5000000', value: '1000000-5000000'},
        {label: '5000000-10000000', value: '5000000-10000000'},
        {label: '10000000-50000000', value: '10000000-50000000'},
        {label: '50000000-100000000', value: '50000000-100000000'},
        {label: '100000000-150000000', value: '100000000-150000000'},
        {label: '150000000-200000000', value: '150000000-200000000'},
        {label: '> 200000000', value: '> 200000000'}
    ],
    avgVolumeAction: [
        {label: 'Any', value: 'Any'},
        {label: 'Volume Up 10% - 25% From 20 Day Average', value: 'Volume Up 10% - 25% From 20 Day Average'},
        {label: 'Volume Up 25% - 50% From 20 Day Average', value: 'Volume Up 25% - 50% From 20 Day Average'},
        {label: 'Volume Up 50% - 100% From 20 Day Average', value: 'Volume Up 50% - 100% From 20 Day Average'},
        {label: 'Volume Up 100% - 200% From 20 Day Average', value: 'Volume Up 100% - 200% From 20 Day Average'},
        {label: 'Volume Up 200% - 400% From 20 Day Average', value: 'Volume Up 200% - 400% From 20 Day Average'},
        {label: 'Volume Up 400% From 20 Day Average', value: 'Volume Up 400% From 20 Day Average'},
    ],
    macd: [
        {label: 'Any', value: 'Any'},
        {label: 'Bullish MACD CrossOver in Last Trading Session', value: 'Bullish MACD CrossOver in Last Trading Session'},
        {label: 'Bullish MACD CrossOver and Above 0 in Last Trading Session', value: 'Bullish MACD CrossOver and Above 0 in Last Trading Session'},
        {label: 'Bullish MACD Fast Line above Signal Line', value: 'Bullish MACD Fast Line above Signal Line'},
        {label: 'Bearish MACD CrossOver in Last Trading Session', value: 'Bearish MACD CrossOver in Last Trading Session'},
        {label: 'Bearish MACD CrossOver and Below 0 in Last Trading Session', value: 'Bearish MACD CrossOver and Below 0 in Last Trading Session'},
        {label: 'Bearish MACD Fast Line below Signal Line', value: 'Bearish MACD Fast Line below Signal Line'},
    ],
    adx10Days: [
        {label: 'Any', value: 'Any'},
        {label: 'ADX (10) Crossed Above 35 and +DI > -DI', value: 'ADX (10) Crossed Above 35 and +DI > -DI'},
        {label: 'ADX (10) Crossed Above 40 and +DI > -DI', value: 'ADX (10) Crossed Above 40 and +DI > -DI'},
        {label: 'ADX (10) Crossed Above 30 and +DI > -DI', value: 'ADX (10) Crossed Above 30 and +DI > -DI'},
        {label: 'ADX (10) Crossed Above 25 and +DI > -DI', value: 'ADX (10) Crossed Above 25 and +DI > -DI'},
        {label: 'ADX (10) Crossed Above 20 and +DI > -DI', value: 'ADX (10) Crossed Above 20 and +DI > -DI'},
        {label: 'ADX (10) Crossed Above 50 and +DI > -DI', value: 'ADX (10) Crossed Above 50 and +DI > -DI'},
        {label: 'ADX (10) In 20 - 25 Range and +DI > -DI', value: 'ADX (10) In 20 - 25 Range and +DI > -DI'},
        {label: 'ADX (10) In 40 - 50 Range and +DI > -DI', value: 'ADX (10) In 40 - 50 Range and +DI > -DI'},
        {label: 'ADX (10) In 35 - 40 Range and +DI > -DI', value: 'ADX (10) In 35 - 40 Range and +DI > -DI'},
        {label: 'ADX (10) Above 50', value: 'ADX (10) Above 50'}
    ],
    adx14Days: [
        {label: 'Any', value: 'Any'},
        {label: 'ADX (14) Crossed Above 35 and +DI > -DI', value: 'ADX (14) Crossed Above 35 and +DI > -DI'},
        {label: 'ADX (14) Crossed Above 40 and +DI > -DI', value: 'ADX (14) Crossed Above 40 and +DI > -DI'},
        {label: 'ADX (14) Crossed Above 30 and +DI > -DI', value: 'ADX (14) Crossed Above 30 and +DI > -DI'},
        {label: 'ADX (14) Crossed Above 25 and +DI > -DI', value: 'ADX (14) Crossed Above 25 and +DI > -DI'},
        {label: 'ADX (14) Crossed Above 20 and +DI > -DI', value: 'ADX (14) Crossed Above 20 and +DI > -DI'},
        {label: 'ADX (14) Crossed Above 50 and +DI > -DI', value: 'ADX (14) Crossed Above 50 and +DI > -DI'},
        {label: 'ADX (14) In 20 - 25 Range and +DI > -DI', value: 'ADX (14) In 20 - 25 Range and +DI > -DI'},
        {label: 'ADX (14) In 40 - 50 Range and +DI > -DI', value: 'ADX (14) In 40 - 50 Range and +DI > -DI'},
        {label: 'ADX (14) In 35 - 40 Range and +DI > -DI', value: 'ADX (14) In 35 - 40 Range and +DI > -DI'},
        {label: 'ADX (14) Above 50', value: 'ADX (14) Above 50'},
    ],
    diCrossover10d: [
        {label: 'Any', value: 'Any'},
        {label: '+DI(10) Crossed Above -DI(10) in Last Trading session', value: '+DI(10) Crossed Above -DI(10) in Last Trading session'},
        {label: '+DI(10) Above -DI(10)', value: '+DI(10) Above -DI(10)'},
        {label: '+DI(10) Crossed Below -DI(10) in Last Trading session', value: '+DI(10) Crossed Below -DI(10) in Last Trading session'},
        {label: '+DI(10) Below -DI(10)', value: '+DI(10) Below -DI(10)'},
    ],
    diCrossover14d: [
        {label: 'Any', value: 'Any'},
        {label: '+DI(14) Crossed Above -DI(14) in Last Trading session', value: '+DI(14) Crossed Above -DI(14) in Last Trading session'},
        {label: '+DI(14) Above -DI(14)', value: '+DI(14) Above -DI(14)'},
        {label: '+DI(14) Crossed Below -DI(14) in Last Trading session', value: '+DI(14) Crossed Below -DI(14) in Last Trading session'},
        {label: '+DI(14) Below -DI(14)', value: '+DI(14) Below -DI(14)'},
    ],
    rsi: [
        {label: 'Any', value: 'Any'},
        {label: 'Crossed Above 30', value: 'Crossed Above 30'},
        {label: 'Crossed Above 50', value: 'Crossed Above 50'},
        {label: 'Crossed Above 70', value: 'Crossed Above 70'},
        {label: 'Crossed Below 70', value: 'Crossed Below 70'},
        {label: 'Crossed Below 50', value: 'Crossed Below 50'},
        {label: 'Crossed Below 30', value: 'Crossed Below 30'},
    ],
    priceChange: [
        {label: 'Any', value: 'Any'},
        {label: 'Up', value: 'Up'},
        {label: 'Up 1%', value: 'Up 1%'},
        {label: 'Up 2%', value: 'Up 2%'},
        {label: 'Up 3%', value: 'Up 3%'},
        {label: 'Up 4%', value: 'Up 4%'},
        {label: 'Up 5%', value: 'Up 5%'},
        {label: 'Up 6%', value: 'Up 6%'},
        {label: 'Up 7%', value: 'Up 7%'},
        {label: 'Up 8%', value: 'Up 8%'},
        {label: 'Up 9%', value: 'Up 9%'},
        {label: 'Up 10%', value: 'Up 10%'},
        {label: 'Up 15%', value: 'Up 15%'},
        {label: 'Up 20%', value: 'Up 20%'},
        {label: 'Down', value: 'Down'},
        {label: 'Down 1%', value: 'Down 1%'},
        {label: 'Down 2%', value: 'Down 2%'},
        {label: 'Down 3%', value: 'Down 3%'},
        {label: 'Down 4%', value: 'Down 4%'},
        {label: 'Down 5%', value: 'Down 5%'},
        {label: 'Down 6%', value: 'Down 6%'},
        {label: 'Down 7%', value: 'Down 7%'},
        {label: 'Down 8%', value: 'Down 8%'},
        {label: 'Down 9%', value: 'Down 9%'},
        {label: 'Down 10%', value: 'Down 10%'},
        {label: 'Down 15%', value: 'Down 15%'},
        {label: 'Down 20%', value: 'Down 20%'}
    ],

    // desc4
    bollBand: [
        {label: 'Any', value: 'Any'},
        {label: 'Closed Above Upper Bollinger Band(20,2)', value: 'Closed Above Upper Bollinger Band(20,2)'},
        {label: 'Closed Below Upper Bollinger Band(20,2)', value: 'Closed Below Upper Bollinger Band(20,2)'},
        {label: 'Price Closed Above Middle BBand', value: 'Price Closed Above Middle BBand'},
        {label: 'Price Closed Below Middle BBand', value: 'Price Closed Below Middle BBand'},
        {label: 'Price Closed Above Lower BBand', value: 'Price Closed Above Lower BBand'},
        {label: 'Price Closed Below Lower BBand', value: 'Price Closed Below Lower BBand'}
    ],
    volumeAction: [
        {label: 'Any', value: 'Any'},
        {label: 'Up 1 to 3%', value: 'Up 1 to 3%'},
        {label: 'Up 3 to 5%', value: 'Up 3 to 5%'},
        {label: 'Up 5 to 10%', value: 'Up 5 to 10%'},
        {label: 'Up 10 to 15%', value: 'Up 10 to 15%'},
        {label: 'Up 15 to 20%', value: 'Up 15 to 20%'},
        {label: 'Up 20 to 30%', value: 'Up 20 to 30%'},
        {label: 'Up 30 to 40%', value: 'Up 30 to 40%'},
        {label: 'Up > 40%', value: 'Up > 40%'},
        {label: 'Down 1 to 3%', value: 'Down 1 to 3%'},
        {label: 'Down 3 to 5%', value: 'Down 3 to 5%'},
        {label: 'Down 5 to 10%', value: 'Down 5 to 10%'},
        {label: 'Down 10 to 15%', value: 'Down 10 to 15%'},
        {label: 'Down 15 to 20%', value: 'Down 15 to 20%'},
        {label: 'Down 20 to 30%', value: 'Down 20 to 30%'},
        {label: 'Down 30 to 40%', value: 'Down 30 to 40%'},
        {label: 'Down > 40%', value: 'Down > 40%'}
    ],
    weeksTrigger: [
        {label: 'Any', value: 'Any'},
        {label: '6 Weeks High Trigger', value: '6 Weeks High Trigger'},
        {label: '6 Weeks Low Trigger', value: '6 Weeks Low Trigger'}
    ],
    forceIndex: [
        {label: 'Any', value: 'Any'},
        {label: 'ForceIndex(13) Bullish', value: 'ForceIndex(13) Bullish'},
        {label: 'ForceIndex(13) Bearish', value: 'ForceIndex(13) Bearish'},
        {label: 'ForceIndex(100) Bullish', value: 'ForceIndex(100) Bullish'},
        {label: 'ForceIndex(100) Bearish', value: 'ForceIndex(100) Bearish'}
    ],
    bullishCandlestick: [
        {label: 'Any', value: 'Any'},
        {label: 'Bullish Enguilfing', value: 'Bullish Enguilfing'},
        {label: 'Bullish Harami', value: 'Bullish Harami'},
        {label: 'Morning Doji Star', value: 'Morning Doji Star'},
        {label: 'Hammer', value: 'Hammer'},
        {label: 'Inverted Hammer', value: 'Inverted Hammer'},

    ],
    bearishCandlestick: [
        {label: 'Any', value: 'Any'},
        {label: 'Bearish Enguilfing', value: 'Bearish Enguilfing'},
        {label: 'Bearish Harami', value: 'Bearish Harami'},
        {label: 'Evening Star Bearish', value: 'Evening Star Bearish'},
        {label: 'Hanging Man', value: 'Hanging Man'},
        {label: 'Shooting Star', value: 'Shooting Star'}
    ]
};

