<div class="row">
    <div class="col">
        <app-right-news></app-right-news>
    </div>
</div>
<div class="row" *ngIf="false">
    <div class="col">
        <app-right-recently-viewed></app-right-recently-viewed>
    </div>
</div>
<div class="row">
    <div class="col">
        <app-right-trending-stocks></app-right-trending-stocks>
    </div>
</div>
<div class="row" *ngIf="false">
    <div class="col">
        <app-right-banner></app-right-banner>
    </div>
</div>
<div class="row">
    <div class="col">
        <app-righ-blogs></app-righ-blogs>
    </div>
</div>