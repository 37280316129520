<div class="login-box-wrapper">
    <div class="login-box">
        <div class="card">
            <div class="card-body login-card-body">
                <button type="button" class="close modal-close" data-dismiss="modal" aria-label="Close" style="margin-top: -10px;"
                    (click)="hideLoginModal()">
                    <span aria-hidden="true">×</span>
                </button>
                <h3 class="text-bold">Register</h3>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="input-group mb-3">
                        <input type="email" class="form-control" placeholder="Email" formControlName="email"
                            (focus)="invalidpasword = false">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                        <div class="text-danger error-message mt-1 w-100 text-left"
                            *ngIf="emai.invalid && (emai.dirty || emai.touched)">
                            <span *ngIf="emai.errors['required']">
                                <i class="fas fa-info-circle mr-2"></i>
                                Email is required
                            </span>
                        </div>
                        <div class="text-danger error-message mt-1 w-100 text-left" *ngIf="invalidpasword">
                            <span>
                                <i class="fas fa-info-circle mr-2"></i>
                                User is already registered
                            </span>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Name" formControlName="name"
                            (focus)="invalidpasword = false">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                        <div class="text-danger error-message mt-1 w-100 text-left"
                            *ngIf="name.invalid && (name.dirty || name.touched)">
                            <span *ngIf="name.errors['required']">
                                <i class="fas fa-info-circle mr-2"></i>
                                Name is required
                            </span>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="password" class="form-control" placeholder="Password" formControlName="password"
                            (focus)="invalidpasword = false">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                        <div class="text-danger error-message mt-1 w-100 text-left"
                            *ngIf="password.invalid && (password.dirty || password.touched)">
                            <span *ngIf="password.errors['required']">
                                <i class="fas fa-info-circle mr-2"></i>
                                Password is required
                            </span>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="password" class="form-control" placeholder="Confirm Password"
                            formControlName="repeatpassword" (focus)="invalidpasword = false">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                        <div class="text-danger error-message mt-1 w-100 text-left"
                            *ngIf="repeatpassword.invalid && (repeatpassword.dirty || repeatpassword.touched)">
                            <span *ngIf="repeatpassword.errors['required']">
                                <i class="fas fa-info-circle mr-2"></i>
                                Confirm password is required
                            </span>
                            <span *ngIf="repeatpassword.errors['compare']">
                                <i class="fas fa-info-circle mr-2"></i>
                                Confirm password was not matched
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary btn-block"
                                [disabled]="loginForm.invalid || isLogginIn">Create An Account</button>
                        </div>
                        <!-- <div class="col-6">
                            <div class="icheck-primary">
                                <p class="mt-2 mb-1">
                                    <a href="forgot-password.html">Forgotten Password</a>
                                </p>
                            </div>
                        </div> -->
                        <!-- /.col -->
                    </div>
                </form>
                <hr />
                <p class="mb-0">
                    Already have an Account? <a href="javascript:void(0)" (click)="onLogginIn()"
                        class="text-center">Sign In</a>
                </p>
            </div>
            <!-- /.login-card-body -->
        </div>
    </div>
</div>