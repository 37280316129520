import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-righ-blogs',
  templateUrl: './righ-blogs.component.html',
  styleUrls: ['./righ-blogs.component.css']
})
export class RighBlogsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
